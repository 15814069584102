import { moment } from "../../../../services";

const Modified = props => {

    const rows = Object.entries(props.rows || {})
        .map(r => ({
            ...r[1],
            key: r[1].key || r[0],
            title: r[1].title || r[0],
        }));

    return <>
        {rows.map(row => {

            let content = <span className="opacity-20">Данных нет</span>;

            // eslint-disable-next-line
            switch (row.key) {
                case "amount": content = <ModifiedAmount row={row} />; break;
                case "client_id": content = <ModifiedClietn row={row} />; break;
                case "event_at": content = <ModifiedEventAt row={row} />; break;
                case "notes": content = <ModifiedNotes row={row} />; break;
            }

            return <div key={row.key} className="flex">
                <div className="opacity-60 me-3">{row.title}</div>
                <div>{content}</div>
            </div>
        })}
    </>;
}

const ModifiedAmount = props => <>
    {props?.row?.new} руб
</>

const ModifiedClietn = props => <>
    {props?.row?.new?.label}
</>

const ModifiedEventAt = props => <>
    {moment(props?.row?.new).format("DD.MM.YYYY, ddd HH:mm")}
</>

const ModifiedNotes = props => <>
    <i>{props?.row?.new}</i>
</>

export default Modified;