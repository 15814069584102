import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { axios, moment } from "../../../../services";
import Content from "../../Content/Content";
import { Description } from "../../../DataDisplay/Description";
import DeviceForm from "./DeviceForm";

const Device = () => {

    const { id, deviceId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [device, setDevice] = useState(null);

    useEffect(() => {

        setLoading(true);

        axios.get(`crm/telemetry/devices/${(deviceId ? deviceId : id)}` + (deviceId ? `/${id}` : ``))
            .then(({ data }) => {
                setError(null);
                setDevice(data || {});
            })
            .catch(e => {
                setError(axios.getError(e));
            })
            .then(() => {
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    return ["create", "edit"].indexOf(id) >= 0
        ? <DeviceForm device={device} updateDevice={setDevice} />
        : <Content>

            <div className="mb-4">

                <h1 className="text-4xl font-bold mb-0">
                    {loading
                        ? <div className="h-[36px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[200px]"></div>
                        : <div>Устройство {device.name ? <div>{device.name}</div> : String(device.id || "").trim()}</div>
                    }
                </h1>

                {device?.number && <h2 className="mt-0 text-gray-700">
                    {device.number}
                </h2>}
            </div>

            {!loading && error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <span className="font-medium">Ошибка!</span> {error}
            </div>}

            {!loading && !error && <div>
                <Description
                    items={[
                        { title: "Тип", value: device?.type_name },
                        { title: "UUID", value: device?.uuid },
                        { title: "Переменная данных", value: <code>{device?.value_name || "value"}</code> },
                        { title: "Последнее значение", value: device?.value },
                        { title: "Создан", value: moment(device?.created_at).format("DD.MM.YYYY HH:mm") },
                        { title: "Обновлен", value: moment(device?.updated_at).format("DD.MM.YYYY HH:mm") },
                    ]}
                />
            </div>}

        </Content>
}

export default Device;