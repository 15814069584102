import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import { appendMessage, setChatFirstMessage } from "../../store/actions/chatGptActions";
import { axios, uuidv4 } from "../../services";
import Message from "./Message";
import TextArea from "./TextArea";
import Loading from "../Chunks/Loading";
import { useNavigate, useParams } from "react-router";
import { URL_PREFIX } from "../../containers/ChatGpt";

const Chat = () => {

    const { loading, messages, currentChatId } = useSelector(s => s.chatGpt);
    const { chatGptLoading } = useActions();
    const { chatId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        chatGptLoading('chat', { chatId: chatId || "" });
        // eslint-disable-next-line
    }, [chatId]);

    useEffect(() => {
        currentChatId && navigate(`${URL_PREFIX}/chat/${currentChatId}`);
        // eslint-disable-next-line
    }, [currentChatId]);

    return <div className="flex flex-col items-stretch h-full">

        {loading && <div className="absolute inset-0 flex items-center justify-center">
            <Loading />
        </div>}

        {!loading && messages.length === 0 && <div className="absolute inset-0 flex items-center justify-center opacity-30">
            <small>Сообщений ещё нет...</small>
        </div>}

        <div className="grow flex flex-col-reverse overflow-y-auto px-1">
            {!loading && messages.map((row, i) => <Message key={i} row={row} />)}
        </div>

        <TextAreaComponent loading={loading} />

    </div>
}

const TextAreaComponent = ({ loading }) => {

    const [message, setMessage] = useState("");
    const d = useDispatch();
    const { chatId } = useParams();

    const sendMessage = () => {

        const data = {
            message,
            uuid: uuidv4(),
        }

        d(appendMessage(data));

        setMessage("");

        const formdata = new FormData();
        formdata.append('message', data.message);
        formdata.append('uuid', data.uuid);
        chatId && formdata.append('chatId', chatId);

        axios.post('openai/chat/send', formdata)
            .then(({ data }) => {

                d(appendMessage(data.message));
                d(setChatFirstMessage(data.message));

                const eventSource = new EventSource(
                    `${axios.defaults.baseURL}openai/chat/streamed?data=${data.streamData}`
                );

                eventSource.onmessage = (e) => {

                    let message = JSON.parse(e.data);

                    d(appendMessage(message));

                    if (message.id) {
                        eventSource.close();
                    }
                };
            })
            .catch(e => {
                d(appendMessage({ ...data, error: true, created_at: new Date() }));
            });
    }

    return <TextArea
        message={message}
        setMessage={setMessage}
        sendMessage={sendMessage}
        loading={loading}
    />
}

export default Chat;