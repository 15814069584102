import { NavLink } from "react-router-dom";
import { CRM_URL_PREFIX } from "../../../containers/Crm/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const LOCAL_STORAGE_MENU_KEY = "crm-menu-open";

const menu = [
    { url: null, title: "Главная", icon: "fa-solid fa-home", end: true },
    { url: "applications", title: "Заявки", icon: "fa-solid fa-list" },
    {
        id: "telemeter",
        title: "Телеметрия",
        icon: "fa-gauge-high",
        items: [
            { url: "telemetry/devices", title: "Устройства" },
            { url: "telemetry/data", title: "Показания" },
            { url: "telemetry/config", title: "Настройки" },
        ],
    },
];

const MenuPart = props => {

    const [menuOpen, setMenuOpen] = useState(
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_MENU_KEY) || "{}")
    );
    const [open, setOpen] = useState(Boolean(menuOpen[`item-${props.id}`]));

    const handleOpen = id => {
        console.log(id);
        menuOpen[`item-${id}`] = !Boolean(menuOpen[`item-${id}`]);
        localStorage.setItem(LOCAL_STORAGE_MENU_KEY, JSON.stringify(menuOpen));
        setMenuOpen(menuOpen);
        setOpen(menuOpen[`item-${id}`]);
    }

    return <div className="grid gap-1">
        <div className="crm-menu-point crm-menu-part relative">
            {props.icon && (
                <div className="menu-icon">
                    <FontAwesomeIcon icon={props.icon} className="opacity-90" />
                </div>
            )}
            <span className="cursor-default">{props.title}</span>
            <span
                onClick={() => handleOpen(props.id)}
                className="absolute top-0 right-0 bottom-0 text-center w-[34px] cursor-pointer hover:bg-gray-300"
                children={<FontAwesomeIcon icon={`fa-solid fa-chevron-${open ? 'up' : 'down'}`} />}
            />
        </div>
        {open && <div className={`ps-6 grid gap-1`}>
            {props.items.map((part, key) => <MenuItem {...part} key={key} />)}
        </div>}
    </div>
}

const MenuItem = props => <NavLink
    className="crm-menu-point"
    to={`${CRM_URL_PREFIX}${props.url ? `/${props.url}` : ""}`}
    end={props.end || null}
    children={
        <>
            {props.icon && (
                <div className="menu-icon">
                    <FontAwesomeIcon icon={props.icon} className="opacity-90" />
                </div>
            )}
            <span>{props.title}</span>
        </>
    }
/>

const Menu = () => {
    return (
        <div className="px-1 py-3 grid gap-1">

            {menu.map((part, key) => typeof part.items == "object"
                ? <MenuPart {...part} id={part.id || key} key={key} />
                : <MenuItem {...part} key={key} />
            )}

        </div>
    );
};

export default Menu;
