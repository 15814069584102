import { useSelector } from "react-redux";
import { Avatar, ConfigProvider } from 'antd';
import { Link } from "react-router-dom";
import Empty from "../Chunks/Empty";
import HeaderUserButton from "../Profile/HeaderButton";

const App = ({ children }) => {

    const { app } = useSelector(s => s);

    return <ConfigProvider renderEmpty={Empty}>
        <div className="flex flex-col min-h-screen">

            <div className="h-16 flex items-center justify-between bg-zinc-800 text-white z-10 sticky top-0">

                <Link
                    to="/"
                    className="mx-3 rounded-md hover:opacity-80 cursor-pointer"
                    children={<Avatar
                        src="/logo192.jpg"
                        size={40}
                        shape="square"
                    />}
                />

                <div className="flex-1"></div>

                {app?.headerRight && <div className="mx-3">{app.headerRight}</div>}

                <HeaderUserButton />

            </div>

            {children}

        </div>
    </ConfigProvider>
};

export default App;