import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Upload } from "antd";
import { axios } from "../../services";
import { setProfileData } from "../../store/actions/appActions";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const AvatarEdit = props => {

    const dispatch = useDispatch();
    const { user } = useSelector(s => s.app);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(user?.avatar || null);

    useEffect(() => {
        setImageUrl(user?.avatar || null);
    }, [user.avatar]);

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
        }

        setLoading(false);
    };

    const remove = () => {

        setLoading(true);

        axios.delete('/user/avatar')
            .then(({ data }) => {
                dispatch(setProfileData(data));
                setImageUrl(false);
            })
            .catch(e => {

            })
            .then(() => {
                setLoading(false);
            });
    }

    const uploadButton = <div>
        {loading
            ? <LoadingOutlined style={{ fontSize: "200%" }} />
            : <PlusOutlined style={{ fontSize: "200%" }} />
        }
        <div style={{ marginTop: 8 }}>Загрузить фото</div>
    </div>

    return <div className="text-center profile-avatar" style={{ height: 208 }}>

        <ImgCrop rotationSlider modalTitle="Подготовка фотографии">

            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                // action={`${process.env.REACT_APP_API_SERVER}/user/avatar`}
                // headers={{
                //     Authorization: `Bearer ${localStorage.getItem('kolgaev_api_token')}`
                // }}
                customRequest={fetch => {

                    const formdata = new FormData();
                    formdata.append('avatar', fetch.file);

                    setLoading(true);

                    axios.post('/user/avatar', formdata, {
                        onUploadProgress: console.log
                    }).then(({ data }) => {
                        dispatch(setProfileData(data));
                    }).catch(e => {

                    }).then(() => {
                        setLoading(false);
                    });
                }}
                // beforeUpload={beforeUpload}
                onChange={handleChange}
                onRemove={() => {
                    axios.delete('/user/avatar')
                        .then(({ data }) => {
                            dispatch(setProfileData(data));
                        });
                }}
            >
                {imageUrl
                    ? <>
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%", opacity: loading ? "0.3" : "1" }}
                        />
                        {loading && <LoadingOutlined className="absolute" style={{ fontSize: "200%" }} />}
                    </>
                    : uploadButton
                }
            </Upload>

            {/* <Upload
                accept="image/*"
                showUploadList={false}
                customRequest={fetch => {

                    const formdata = new FormData;
                    formdata.append('file', fetch.file);

                    axios.post('/user/avatar', formdata, {
                        onUploadProgress: console.log
                    }).then(({ data }) => {

                        setFileList(data?.avatar ? [{
                            uid: "-1",
                            name: "avatar",
                            status: "done",
                            url: data.avatar,
                        }] : []);

                        dispatch(setProfileData(data));
                    }).catch(e => {

                    });
                }}
                // action={`${process.env.REACT_APP_API_SERVER}/user/avatar`}
                // headers={{
                //     Authorization: `Bearer ${localStorage.getItem('kolgaev_api_token')}`
                // }}
                listType="picture-card"
                // onPreview={handlePreview}
                onChange={onChange}
                fileList={fileList}
                onRemove={() => {
                    axios.delete('/user/avatar')
                        .then(({ data }) => {
                            dispatch(setProfileData(data));
                        });
                }}
            >
                {fileList.length >= 1 ? null : <PlusOutlined />}
            </Upload> */}

        </ImgCrop >

        <div>
            <div className={`${user?.avatar ? "visible" : "invisible"} cursor-pointer opacity-30 hover:opacity-90 max-w-xs m-auto`} onClick={remove}>Удалить фото</div>
        </div>

    </div>
}

export default AvatarEdit;