import { Input, Modal } from "antd";
import { useSelector } from "react-redux";
import { useActions } from "../../../hooks/useActions";
import { Typography } from 'antd';
import { useCallback, useEffect, useRef, useState } from "react";
import { axios } from "../../../services";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const { Title, Text } = Typography;

const CreateFolder = () => {

    const { showModalCreateFolder } = useSelector(s => s.disk);
    const { setShowCreateFolder, appendFolder } = useActions();
    const [folderName, setFolderName] = useState("Новая папка");
    const { folder } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {

        showModalCreateFolder && inputRef.current && inputRef.current.focus({
            cursor: 'end',
        });

        !showModalCreateFolder && inputRef.current && inputRef.current.blur();

        return () => {
            setFolderName("Новая папка");
            setLoading(false);
            setError(null);
        }

    }, [showModalCreateFolder]);

    const handleOk = useCallback(params => {

        setLoading(true);

        axios.post('disk/folder', params)
            .then(({ data }) => {
                setError(null);
                setShowCreateFolder(false);
                appendFolder(data);
            })
            .catch(e => setError(axios.getError(e)))
            .then(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    return <Modal
        open={showModalCreateFolder}
        onOk={() => handleOk({ folder, name: folderName })}
        onCancel={() => setShowCreateFolder(false)}
        cancelText="Отмена"
        cancelButtonProps={{
            style: { display: "none" },
        }}
        okText="Создать"
        okButtonProps={{
            icon: <FontAwesomeIcon icon={faSave} className="me-3" />,
            style: { width: 120 },
            loading: loading,
        }}
        children={<div>

            <Title level={3} className="m-0">Новая папка</Title>

            <div className="mt-5">

                <Input
                    id="folder_name"
                    placeholder="Укажите имя для новой папки"
                    className="w-full"
                    name="folder_name"
                    autoComplete="folder_name"
                    size="large"
                    value={folderName}
                    onChange={({ target }) => setFolderName(target?.value)}
                    disabled={loading}
                    status={error ? "error" : null}
                    ref={inputRef}
                />
            </div>

            {error && <Text type="danger">{error}</Text>}

        </div>}
    />
}

export default CreateFolder;