import axios from "axios";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}/`,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});

instance.interceptors.request.use((config) => {

    const token = localStorage.getItem('kolgaev_api_token');

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
    }

    return config;

});

instance.getCatch = e => {

    if (!e) {
        return {};
    }

    return {
        status: e?.response?.status || 400,
        statusText: e?.response?.statusText,
        message: instance.getError(e),
        errors: instance.getErrors(e),
    }
}

/**
 * Обработка ошибок
 * 
 * @param {object} error Объект ошибки
 * @param {string} type Тип данных на вывод
 * @param {function|null} Отладочная функция
 * @returns
 */
instance.getError = (error, type = "message", callback = null) => {

    const response = {}

    if (error.response) {

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        response.message = error.response?.data?.message || error.response.statusText;
        response.data = error.response.data;
        response.status = error.response.status;
        response.statusText = error.response.statusText;
        response.headers = error.response.headers;

        if (typeof callback == "function")
            callback(error.response);

    } else if (error.request) {

        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the 
        // browser and an instance of
        // http.ClientRequest in node.js

        response.message = error.request?.data?.message || "Неизвестная ошибка";

        if (typeof callback == "function")
            callback(error.request);

    } else {

        // Something happened in setting up the request that triggered an Error

        response.message = error.message;

        if (typeof callback == "function")
            callback('Error ' + error.message);

    }

    if (typeof callback == "function")
        callback(error.config);

    return response[type] || null;

}

/** Вывод объекта ошибок при валидации данных */
instance.getErrors = error => error?.response?.data?.errors || {}

export default instance;