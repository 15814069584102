import axios from "./axios";
import moment from "./moment";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

function queryString(obj) {
    var queryString = "";
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            queryString += encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]) + "&";
        }
    }
    return queryString.slice(0, -1);
}

export {
    axios,
    moment,
    uuidv4,
    queryString,
}