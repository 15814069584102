import { InputNumber } from "antd"

const Number = props => {

    const { name, placeholder } = props;
    const { formdata, setFormdata } = props;
    const value = formdata[name] || null;

    return <InputNumber
        placeholder={placeholder}
        step="0.01"
        stringMode
        className="w-full"
        value={value}
        onChange={value => setFormdata(p => ({ ...p, [name]: value }))}
    />
}

export default Number;