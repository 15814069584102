import * as TYPES from "../types/disk";

const initialState = {
    loading: false,
    folder: null,
    files: [],
    meta: {},
    showModalCreateFolder: false, // Открыть модальное окно содание каталога
    uploadFilesList: null, // Список загружаемых файлов
    uploadList: [], // Список файлов в загрузке
    uploadSize: 0, // Размер загружаемых файлов
    uploadedSize: 0, // Размер завершенной загрузки
    uploadedPercent: 0, // Процент процесса загрузки
}

export const diskReducer = (state = initialState, action) => {

    switch (action.type) {

        case TYPES.DISK_FETCH_FILES_LOADING:
            return { ...state, loading: action.payload }

        case TYPES.DISK_SET_FOLDER:
            return {
                ...state,
                folder: action.payload,
                files: state.folder !== action.payload ? [] : state.files,
                meta: {},
            }

        case TYPES.DISK_APPEND_FOLDER:

            let filesWithFolder = [];
            let foundFolder = state.files.find(file => file.id === action.payload.id);

            if (!foundFolder) {
                filesWithFolder.push(action.payload);
            }

            state.files.forEach(file => {
                if (file.id === action.payload.id) {
                    filesWithFolder.push(action.payload);
                } else {
                    filesWithFolder.push(file);
                }
            });

            return { ...state, files: filesWithFolder }

        case TYPES.DISK_SET_FILES_META:
            return { ...state, meta: action.payload }

        case TYPES.DISK_SET_FILES:
            return { ...state, files: action.payload }

        case TYPES.DISK_APPEND_FILES:
            return { ...state, files: [...state.files, ...action.payload] }

        case TYPES.DISK_MODAL_SHOW_CREATE_FOLDER:
            return { ...state, showModalCreateFolder: Boolean(action.payload) }

        case TYPES.DISK_UPLOAD_SET_LIST:

            let uploadFilesList = Array.from(state.uploadFilesList || [])
                .concat(Array.from(action.payload));

            let uploadList = state.uploadList || [];

            uploadFilesList.forEach((file, i) => {
                if (!uploadList[i]) {
                    uploadList.push({
                        key: i,
                        name: file.name,
                        lastModified: file.lastModified, // Время последнего изменения файла
                        lastModifiedDate: file.lastModifiedDate, // Время последнего изменения файла
                        size: file.size, // Размер файла
                        uploaded: 0, // Размер загруженной части
                        chunkOffset: 0,
                        percent: 0, // Процент загрузки файла
                        type: file.type, // MIME-тип файла,
                        status: "queue",
                        path: null,
                    })
                }
            });

            let uploadSize = uploadList.reduce((a, f) => a + Number(f.size), 0);
            let uploadedSize = uploadList.reduce((a, f) => a + Number(f.uploaded), 0);

            return {
                ...state,
                uploadFilesList,
                uploadList,
                uploadSize,
                uploadedSize,
                uploadedPercent: uploadSize > 0 ? (uploadedSize * 100) / uploadSize : 0,
            }

        case TYPES.DISK_UPLOAD_SET_FILE:

            let uploadFileListItems = state?.uploadList || [];
            let uploadFileItem = uploadFileListItems[action?.payload?.key] || {};
            uploadFileListItems[action?.payload?.key] = { ...uploadFileItem, ...(action?.payload?.item || {}) };

            let itemsUploadSize = uploadFileListItems.reduce((a, f) => a + Number(f.size), 0);
            let itemsUploadedSize = uploadFileListItems.reduce((a, f) => a + Number(f.uploaded), 0);

            return {
                ...state,
                uploadList: uploadFileListItems,
                uploadSize: itemsUploadSize,
                uploadedSize: itemsUploadedSize,
                uploadedPercent: itemsUploadSize > 0 ? (itemsUploadedSize * 100) / itemsUploadSize : 0,
            }

        default:
            return { ...state }
    }

}