import { Route, Routes } from "react-router";
import NotFound from "../../components/Errors/NotFound";
import Files from "../../components/Disk/Files";
import { useActions } from "../../hooks/useActions";
import { useEffect } from "react";
import RightHeader from "../../components/Disk/Menu/RightHeader";

const Disk = () => {

    const { setHeaderRight } = useActions();

    useEffect(() => {
        setHeaderRight(<RightHeader />);
        return () => setHeaderRight(null);
        // eslint-disable-next-line
    }, []);

    return <div className="flex-grow flex">

        {/* <div className="w-60 border-0 border-r border-solid border-r-gray-100 bg-gray-50">
            <Menu />
        </div> */}

        <div className="flex-grow bg-white relative p-3">
            <Routes>
                <Route path="/" element={<Files />} />
                <Route path=":folder" element={<Files />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>

    </div>
}

export default Disk;