import { Button, Divider, Select, Spin } from "antd"
import { useEffect, useMemo, useRef, useState } from "react";
import { axios } from "../../services";
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";

const fetchOptions = async (url, search = null) => {

    const options = [];

    await axios.get(url, { params: { search } })
        .then(({ data }) => {
            data.data.forEach(({ value, label }) => options.push({ value, label }))
        });

    return options;
}

const Search = props => {

    const { name, fetchUrl, placeholder, debounceTimeout = 300 } = props;
    const [options, setOptions] = useState(props.options || []);
    const [search, setSearch] = useState("");
    const [fetching, setFetching] = useState(false);
    const fetchRef = useRef(0);
    const { setShowModalAddClient } = useActions();
    const { clients } = useSelector(s => s.crm);

    const { formdata, setFormdata } = props;
    const value = formdata[name] || null;

    const debounceFetcher = useMemo(() => {

        const loadOptions = (value) => {

            fetchRef.current += 1;
            const fetchId = fetchRef.current;

            setFetching(true);

            fetchOptions(fetchUrl, value)
                .then(newOptions => {

                    if (fetchId !== fetchRef.current) {
                        return;
                    }

                    setSearch(value);
                    setOptions(newOptions);
                    setFetching(false);
                });
        };

        return debounce(loadOptions, debounceTimeout);

        // eslint-disable-next-line
    }, [fetchUrl]);

    useEffect(() => {

        if (fetchUrl) {

            setFetching(true);
            setSearch("");

            fetchOptions(fetchUrl, search)
                .then(options => {
                    setFetching(false);
                    setOptions(options);
                });
        }

        // eslint-disable-next-line
    }, [fetchUrl]);

    useEffect(() => {
        if ((clients || []).length >= 0 && name === "client_id") {

            setFetching(true);

            fetchOptions(fetchUrl, search)
                .then(newOptions => {
                    setOptions(newOptions);
                    setFetching(false);
                });
        }
        // eslint-disable-next-line
    }, [clients]);

    return <Select
        showSearch
        labelInValue
        // filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterOption={false}
        onSearch={debounceFetcher}
        placeholder={placeholder}
        options={options}
        loading={fetching}
        notFoundContent={fetching ? <Spin size="small" /> : <div>Ничего не найдено...</div>}
        value={value ?? ""}
        onChange={({ value }) => setFormdata(p => ({ ...p, [name]: value }))}
        dropdownRender={(menu) => <>
            {menu}
            {name === "client_id" && <>
                <Divider
                    style={{ margin: '8px 0' }}
                />
                <Button
                    type="primary"
                    icon={<FontAwesomeIcon
                        icon={faPlus}
                        className="me-3"
                    />}
                    onClick={() => setShowModalAddClient(true)}
                    block
                    children="Добавить клиента"
                />
            </>}
        </>}
    />
}

export default Search;