import { Form } from "antd";
import Datetime from "./Datetime";
import Number from "./Number";
import Select from "./Select";
import Search from "./Search";
import Textarea from "./Textarea";
import String from "./String";

const FormItem = props => {

    const {
        name,
        label,
        rules,
        required,
        validateStatus,
        help,
    } = props;

    // const p = { ...props };

    // if (p?.formRef) {
    //     delete p.formRef;
    // }

    // if (p?.addItem) {
    //     delete p.addItem;
    // }

    // if (p?.values) {
    //     delete p.values;
    // }

    // if (Object.keys(p).indexOf('fetchUrl') >= 0) {
    //     delete p.fetchUrl;
    // }

    return <Form.Item
        name={name}
        label={label}
        help={help}
        rules={rules}
        validateStatus={validateStatus}
        required={Boolean(required)}
        children={<Input {...props} />}
        className="mb-3"
    />
}

const Input = props => {

    const { type } = props;

    let children = null;

    switch (type) {

        case "datetime":
            children = <Datetime {...props} />
            break;

        case "number":
            children = <Number {...props} />
            break;

        case "search":
            children = <Search {...props} />
            break;

        case "select":
            children = <Select {...props} />
            break;

        case "textarea":
            children = <Textarea {...props} />
            break;

        default:
            children = <String {...props} />
    }

    return children;
}

export default FormItem;