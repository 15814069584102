import { Badge, Descriptions, Typography } from "antd";
import Client from "../Components/Client";
import { moment } from "../../../services";
import Actions from "./Actions";

const { Title } = Typography;

const DescriptionsTitle = props => <Title
    level={2}
    className="flex items-center"
    children={<>
        <Actions {...props} className="me-3 text-black" noShow />
        <span className="flex-grow">{props.number}</span>
    </>}
/>

const ApplicationPageData = props => {

    const { lead } = props;

    return <div className="max-w-2xl w-full mx-2">

        <Descriptions title={<DescriptionsTitle {...lead} />} bordered size="small">

            <Descriptions.Item label="Статус" span={3}>
                {lead.status && <Badge
                    status="processing"
                    color={lead.status.color}
                    text={lead.status.name}
                />}
            </Descriptions.Item>

            <Descriptions.Item label="Тип заявки" span={3}>
                {lead?.event_type && lead.event_type.name}
            </Descriptions.Item>

            <Descriptions.Item label="Дата" span={3}>
                {lead?.event_at && moment(lead.event_at).format("DD MMM YYYY, ddd HH:mm")}
            </Descriptions.Item>

            <Descriptions.Item label="Сумма" span={3}>
                {lead?.amount && lead.amount}
            </Descriptions.Item>

            <Descriptions.Item label="Клиент" span={3}>
                {lead?.client && <Client {...lead.client} />}
            </Descriptions.Item>

            <Descriptions.Item label="Примечание" span={3}>
                {lead?.notes && lead.notes}
            </Descriptions.Item>

        </Descriptions>

    </div>
}

export default ApplicationPageData;