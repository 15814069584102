import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import { appendMessage } from "../../store/actions/chatGptActions";
import { axios, uuidv4 } from "../../services";
import Message from "./Message";
import TextArea from "./TextArea";
import Loading from "../Chunks/Loading";

const ChatImages = () => {

    const { loading, messages } = useSelector(s => s.chatGpt);
    const { chatGptLoading } = useActions();

    useEffect(() => {
        chatGptLoading('images');
        // eslint-disable-next-line
    }, []);

    return <div className="flex flex-col items-stretch h-full">

        {loading && <div className="absolute inset-0 flex items-center justify-center">
            <Loading />
        </div>}

        <div className="grow flex flex-col-reverse overflow-y-auto px-1">
            {!loading && messages.map((row, i) => <Message key={i} row={row} />)}
        </div>

        <TextAreaComponent loading={loading} />

    </div>

}

const TextAreaComponent = ({ loading }) => {

    const [message, setMessage] = useState("");
    const d = useDispatch();

    const sendMessage = () => {

        const data = {
            message,
            uuid: uuidv4(),
        }

        d(appendMessage(data));

        setMessage("");

        const formdata = new FormData();
        formdata.append('message', data.message);
        formdata.append('uuid', data.uuid);

        axios.post('openai/images/send', formdata)
            .then(({ data }) => {
                d(appendMessage(data.message));
            })
            .catch(e => {
                d(appendMessage({ ...data, error: true, created_at: new Date() }));
            });
    }

    return <TextArea
        message={message}
        setMessage={setMessage}
        sendMessage={sendMessage}
        loading={loading}
    />
}

export default ChatImages;