import { Route, Routes } from "react-router";
import Menu from "../../components/Crm/Menu/Menu";
import "../../styles/crm.css";
import NotFound from "../../components/Errors/NotFound";
import Applications from "./Applications/Applications";
import Application from "./Applications/Application";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import Right from "../../components/Crm/Header/Right";
import ClientModal from "../../components/Crm/Modals/ClientModal";
import ApplicationModal from "../../components/Crm/Modals/ApplicationModal";
import Config from "./Telemetry/Config";
import Devices from "./Telemetry/Devices";
import Data from "./Telemetry/Data";

export const CRM_URL_PREFIX = "/crm";

export const Main = () => {

    const { setHeaderRight } = useActions();

    useEffect(() => {
        setHeaderRight(<Right />);
        return () => setHeaderRight(null);
        // eslint-disable-next-line
    }, []);

    return <div className="flex-grow flex">

        <ClientModal />
        <ApplicationModal />

        <div className="w-60 border-0 border-r border-solid bg-white border-r-white dark:bg-gray-800 dark:border-r-gray-800">
            <Menu />
        </div>

        <div className="flex-grow bg-white dark:bg-gray-800 relative p-3 text-gray-900 dark:text-white">
            <Routes>
                <Route path="/applications" element={<Applications />} />
                <Route path="/applications/:id" element={<Application />} />
                <Route path="/telemetry/config" element={<Config />} />
                <Route path="/telemetry/devices" element={<Devices />} />
                <Route path="/telemetry/devices/:id" element={<Devices />} />
                <Route path="/telemetry/devices/:id/:deviceId" element={<Devices />} />
                <Route path="/telemetry/data" element={<Data />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>

    </div>
}

export default Main;