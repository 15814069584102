export const SET_SHOW_MODAL_ADD_CLIENT = "SET_SHOW_MODAL_ADD_CLIENT";
export const SET_SHOW_MODAL_ADD_APPLICATION = "SET_SHOW_MODAL_ADD_APPLICATION";

export const CRM_CLIENT_APPEND_NEW_CLIENT = "CRM_CLIENT_APPEND_NEW_CLIENT";

export const CRM_APPLICATIONS_FETCH_LOADING = "CRM_APPLICATIONS_FETCH_LOADING";
export const CRM_APPLICATIONS_FETCH_ERROR = "CRM_APPLICATIONS_FETCH_ERROR";
export const CRM_APPLICATIONS_FETCH_SUCCESS = "CRM_APPLICATIONS_FETCH_SUCCESS";
export const CRM_APPLICATION_APPEND = "CRM_APPLICATION_APPEND";
export const CRM_APPLICATION_SELECTED = "CRM_APPLICATION_SELECTED";

export const CRM_APPLICATION_TABLE_PARAMS = "CRM_APPLICATION_TABLE_PARAMS";
export const CRM_APPLICATION_TABLE_PARAMS_PAGINATION = "CRM_APPLICATION_TABLE_PARAMS_PAGINATION";
