import { useEffect, useState } from "react";
import { axios } from "../../../services";
import Content from "../../../components/Crm/Content/Content";
import Spinner from "../../../components/Chunks/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import useCopy from "../../../hooks/useCopy";

const Config = () => {

    const [formdata, setFormdata] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { copyToClipboard } = useCopy();

    useEffect(() => {

        setLoading(true);

        axios.get('crm/telemetry')
            .then(({ data }) => {
                setError(null);
                setFormdata(data);
            })
            .catch(e => {
                setError(axios.getError(e));
            })
            .then(() => {
                setLoading(false);
            });

    }, []);

    return <Content>

        <h1 className="text-4xl font-bold dark:text-white">Телеметрия</h1>

        {loading && <Spinner />}

        {!loading && error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <span className="font-medium">Ошибка!</span> {error}
        </div>}

        {!loading && !error && <div>

            <h6 className="text-lg font-bold dark:text-white mb-3">Токен доступа</h6>

            <p className="text-gray-500 dark:text-gray-400">
                Токен доступа служит для аутентификации запроса с ваших устройств. Токен необходимо передать в теле запроса или query переменной с ключем <code>accessToken</code> или в заголовке <code>X-Access-Token</code>
            </p>

            <div className="w-full mt-2">
                <strong>Ваш токен:</strong>
                <div className="mt-2 px-3 py-2 w-full text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500 flex items-center justify-between">
                    <pre className="m-1">{formdata?.accessToken}</pre>
                    <FontAwesomeIcon
                        icon={faCopy}
                        className="opacity-70 hover:opacity-100 cursor-pointer"
                        onClick={() => copyToClipboard(formdata?.accessToken, "Токен скопирован в буфер")}
                    />
                </div>
            </div>

        </div>}
    </Content>
}

export default Config;