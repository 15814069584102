import { useDispatch, useSelector } from "react-redux";
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Alert, Button, Drawer, Input, Modal } from 'antd';
import { useState } from 'react';
import { useActions } from "../../hooks/useActions";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Services from "../Auth/Services";
import HeaderUserButton from "../Profile/HeaderButton";

const MainHeader = () => {

    const d = useDispatch();
    const app = useSelector(s => s.app);
    const { fetchLogin } = useActions();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formdata, setFormdata] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        d({ type: "FETCH_CATCH", payload: null })
        setIsModalOpen(true);
    }

    const handleOk = () => {
        setIsModalOpen(false);
    }

    const handleCancel = () => {
        !Boolean(app?.authServiceLoading) && setIsModalOpen(false);
    }

    return <header className="absolute inset-x-0 top-0 z-50">

        <nav className="flex items-center justify-between h-16">

            <div className="flex flex-1">

                {app?.user && app?.parts && <FontAwesomeIcon
                    icon={faBars}
                    className="text-gray-400 hover:text-white mx-3 cursor-pointer"
                    size="xl"
                    onClick={() => setOpen(o => !o)}
                />}

                <Drawer
                    title="Kolgaev.ru"
                    placement="left"
                    onClose={() => setOpen(false)}
                    open={open}
                    key="left"
                    className="main-menu bg-slate-900 text-white"
                >
                    {app?.parts && <div className="flex flex-col">
                        {app.parts.map(r => <Link
                            key={r.url}
                            to={r.url}
                            children={r.name}
                            className="
                                text-sm
                                font-semibold
                                leading-6
                                text-gray-400
                                hover:text-gray-400
                                no-underline
                                mb-1
                                px-3
                                py-2
                                rounded-lg
                                hover:bg-slate-950/20
                            "
                        />)}
                    </div>}
                </Drawer>

                {/* <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
                </a> */}
            </div>
            {/* <div className="flex lg:hidden">
                <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
                    <span className="sr-only">Open main menu</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div> */}

            {app?.parts && <div className="hidden lg:flex lg:gap-x-12">
                {app.parts.map(r => <Link
                    key={r.url}
                    to={r.url}
                    children={r.name}
                    className="text-sm font-semibold leading-6 text-gray-400 hover:text-white no-underline"
                />)}
            </div>}

            <div className="flex flex-1 justify-end">

                {!app?.user && <span
                    className="text-sm font-semibold leading-6 text-gray-100 hover:text-gray-300 cursor-pointer mx-3"
                    children={<>Вход <span>&rarr;</span></>}
                    onClick={showModal}
                />}

                <HeaderUserButton />

            </div>

        </nav>

        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>

            <div className="flex min-h-full items-center justify-center px-4 py-8 sm:px-6 lg:px-8">

                <div className="w-full max-w-md space-y-8">
                    <div className="text-center">
                        <img
                            className="mx-auto h-12 w-auto rounded-md"
                            src="/logo192.jpg"
                            alt="Your Company"
                        />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Авторизация
                        </h2>
                    </div>
                    <form className="mt-8 space-y-6" action="#" method="POST">
                        <input type="hidden" name="remember" defaultValue="true" />

                        <div>

                            <div className="mb-5">

                                <label htmlFor="login" className="mb-2 px-1">
                                    <strong>Email адрес</strong>
                                </label>

                                <Input
                                    id="login"
                                    placeholder="Email адрес"
                                    className="w-full"
                                    name="login"
                                    autoComplete="login"
                                    size="large"
                                    onChange={({ target }) => setFormdata(p => ({ ...p, login: target?.value }))}
                                    disabled={Boolean(app?.authServiceLoading)}
                                />
                            </div>

                            <div>

                                <label htmlFor="password" className="mb-2 px-1">
                                    <strong>Пароль</strong>
                                </label>

                                <Input.Password
                                    className="w-full"
                                    name="password"
                                    autoComplete="password"
                                    placeholder="Пароль"
                                    size="large"
                                    onChange={({ target }) => setFormdata(p => ({ ...p, password: target?.value }))}
                                    disabled={Boolean(app?.authServiceLoading)}
                                />
                            </div>
                        </div>

                        {/* <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    Запомнить меня
                                </label>
                            </div>
                        </div> */}

                        {app?.error && <Alert message={app.error} type="error" showIcon />}

                        <Button
                            type="primary"
                            size="large"
                            block
                            className="group text-sm font-semibold text-white"
                            children={<>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <LockClosedIcon className="h-5 w-5 text-white" />
                                </span>
                                Вход
                            </>}
                            onClick={() => fetchLogin(
                                formdata,
                                setLoading,
                                () => setIsModalOpen(false),
                            )}
                            loading={loading}
                            disabled={Boolean(app?.authServiceLoading)}
                        />

                        <Services />

                    </form>
                </div>
            </div>
        </Modal>

    </header>
}

export default MainHeader;