import { useNavigate } from "react-router";

const Item = props => {

    const { itemKey, title, href } = props;
    const nav = useNavigate();

    return <>
        {itemKey > 0 && <span className="opacity-50 mx-3">/</span>}
        <span
            className="opacity-50 hover:opacity-100 cursor-pointer hover:underline"
            onClick={() => href && nav(href)}
            children={title}
        />
    </>
}

const Breadcrumb = props => {

    const { items, className } = props;

    return <div className={`flex items-center cursor-default ${className || ""}`}>
        {typeof items == "object" && items.map((item, key) => <Item key={key} {...item} itemKey={key} />)}
    </div>
}

export default Breadcrumb;