export const Description = props => {

    const items = props.items || [];

    return <div className="bg-white dark:bg-gray-800">
        {items.map((item, key) => <Item key={key} {...item} />)}
    </div>
}

export const Item = props => {
    return <div className="mb-4">
        <div><strong>{props.title}</strong></div>
        <div>{props.value || <span>- - -</span>}</div>
    </div>
}

const toExport = {
    Description
}

export default toExport;