import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { axios } from "../../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircleNotch, faClose } from "@fortawesome/free-solid-svg-icons";

const Show = () => {

    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const photo = searchParams.get('photo');

    const [url, setUrl] = useState(null);
    const [prev, setPrev] = useState(null);
    const [next, setNext] = useState(null);
    const [error, setError] = useState(null);

    const handleChangePhoto = id => {
        searchParams.set('photo', id);
        return navigate(`${pathname}?${searchParams.toString()}`);
    }

    const closeShow = () => {
        searchParams.delete('photo');
        console.log(searchParams.toString());
        return navigate(`${pathname}?${searchParams.toString()}`);
    }

    useEffect(() => {

        setUrl(null);
        setPrev(null);
        setNext(null);
        setError(null);

        if (photo) {

            axios.get(`disk/photo/${photo}`)
                .then(({ data }) => {
                    data?.url && setUrl(data.url);
                    setPrev(data?.prev || null);
                    setNext(data?.next || null);
                    setError(null);
                })
                .catch(e => {
                    setError(axios.getError(e));
                });
        }
        // eslint-disable-next-line
    }, [photo]);

    return photo && <div className="fixed inset-0 w-full h-full bg-black bg-opacity-90 z-10">

        <div className="w-full h-full flex justify-center items-center">
            {error && <div className="text-red-300 opacity-70">
                {error}
            </div>}
            {!error && url && <img alt="Фотокарточка" src={url} className="m-auto rounded-sm max-w-full max-h-full" />}
            {!error && !url && <div>
                <FontAwesomeIcon icon={faCircleNotch} spin size="2x" className="text-white opacity-40" />
            </div>}
        </div>

        <div className="fixed inset-0 w-full h-screen z-20 flex flex-col">
            <div
                className="flex justify-end opacity-25 hover:opacity-80 cursor-pointer py-2 px-3"
                onClick={() => closeShow()}
                children={<FontAwesomeIcon
                    icon={faClose}
                    className="text-white m-2"
                    size="2x"
                />}
            />
            <div className="flex justify-center flex-grow items-center">
                {prev && <div
                    onClick={() => handleChangePhoto(prev)}
                    className="h-full opacity-25 hover:opacity-80 cursor-pointer px-3 flex items-center"
                    children={<FontAwesomeIcon
                        icon={faChevronLeft}
                        className="text-white m-2"
                        size="2x"
                    />}
                />}
                <div className="flex-grow">{' '}</div>
                {next && <div
                    onClick={() => handleChangePhoto(next)}
                    className="h-full opacity-25 hover:opacity-80 cursor-pointer px-3 flex items-center"
                    children={<FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-white m-2"
                        size="2x"
                    />}
                />}
            </div>
            <div className="my-5"></div>
        </div>

    </div>
}

export default Show;