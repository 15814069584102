import { Form, Input, Modal, notification } from "antd"
import { useSelector } from "react-redux"
import { useActions } from "../../../hooks/useActions";
import Title from "antd/es/typography/Title";
import { useEffect, useRef, useState } from "react";
import Loading from "../../Parts/Loading";
import { axios } from "../../../services";

const ClientModal = () => {

    const { modalAddClientShow } = useSelector(s => s.crm);
    const { setShowModalAddClient, crmClientAppendNew } = useActions();
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => setTimeout(() => {
            setLoading(false);
            // eslint-disable-next-line
            formRef.current?.resetFields();
        }, 500);
        // eslint-disable-next-line
    }, [modalAddClientShow]);

    const onFinish = (values) => {

        setLoading(true);

        axios.post('crm/clients', values)
            .then(({ data }) => {
                setShowModalAddClient(false);
                crmClientAppendNew(data);
            })
            .catch(e => {
                let error = axios.getCatch(e);
                notification.error({
                    message: error.statusText,
                    description: error.message,
                    duration: 10,
                });
            })
            .then(() => setLoading(false));

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleCancel = () => setShowModalAddClient(false);

    const handleOk = () => formRef.current?.submit();

    return <Modal
        open={modalAddClientShow}
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Добавить"
        cancelText="Отмена"
        children={<>

            {loading && <Loading />}

            <Title level={3} className="mt-0">Новый клиент</Title>

            <Form
                ref={formRef}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="my-6"
                children={<>

                    <Form.Item
                        name="firstname"
                        label="Имя"
                        required
                        children={<Input />}
                        rules={[
                            { required: true, message: 'Необходимо указать имя' },
                        ]}
                    />

                    <Form.Item
                        name="lastname"
                        label="Фамилия"
                        children={<Input />}
                    />

                    <Form.Item
                        name="patronymic"
                        label="Отчество"
                        children={<Input />}
                    />

                    <Form.Item
                        name="email"
                        label="Email"
                        children={<Input />}
                    />

                    <Form.Item
                        name="phone"
                        label="Телефон"
                        children={<Input />}
                    />
                </>}
            />
        </>}
    />
}

export default ClientModal;