import { Modal, Progress } from "antd";
import { useSelector } from "react-redux";
import UploadFile from "./UploadFile";

const UploadFiles = () => {

    const { uploadList, uploadedPercent } = useSelector(s => s.disk)
    const isUpload = (uploadList || []).length > 0;
    const uploadFile = uploadList.find(item => item.status === "upload");
    const after = [],
        before = [];

    if (typeof uploadList == "object") {
        let current = false;
        uploadList.forEach(file => {
            if (uploadFile?.key === file.key) {
                current = true;
                return;
            }
            current && before.unshift(file);
            !current && after.push(file);
        })
    }

    console.log(before, after, uploadList.find(item => item.status === "upload"));

    return <Modal
        open={isUpload}
        footer={null}
        width={800}
        maskClosable={false}
        closable={false}
    >

        <div className="flex items-center">
            <h2 className="grow">Загрузка файлов...</h2>
            <span>{typeof uploadFile?.key == "number" ? uploadFile?.key + 1 : 1} / {typeof uploadList == "object" ? uploadList.length : 0}</span>
        </div>

        <Progress percent={uploadedPercent} showInfo={false} status="active" />

        <div className="flex justify-between mt-3">
            <UploadFile data={after[1] || null} type="after" />
            <UploadFile data={after[0] || null} type="after" />
            <UploadFile data={uploadFile || null} type="current" />
            <UploadFile data={before[0] || null} type="before" />
            <UploadFile data={before[1] || null} type="before" />
        </div>

        {uploadFile && <div className="text-center mt-4">
            <strong>{uploadFile.name}</strong>
        </div>}

        {/* {typeof uploadList == "object" && uploadList.map((file, key) => {
            return <div key={key}>
                <pre>{JSON.stringify(file, "", 4)}</pre>
            </div>
        })} */}

    </Modal>
}

export default UploadFiles;