import { useState } from "react";
import ProfileContents from "../../components/Profile/ProfileContents"
import { useEffect } from "react";
import { axios } from "../../services";
import { Alert, Avatar, List, Popconfirm, Skeleton, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Accounts = () => {

    const [error, setError] = useState(null);
    const [list, setList] = useState([{ loading: true }, { loading: true }]);

    useEffect(() => {
        axios.get('user/accounts')
            .then(({ data: { data } }) => {
                setError(null);
                setList(data);
            })
            .catch(e => {
                setError(axios.getError(e));
            });
    }, []);

    return <ProfileContents
        title="Аккаунты"
        content={<>
            {error && <Alert type="error" message={error} showIcon />}
            {!error && <List
                itemLayout="horizontal"
                dataSource={list}
                renderItem={item => <Item
                    item={item}
                    key={item.id}
                    setList={setList}
                />}
            />}

        </>}
    />
}

const Item = props => {

    const { item, setList } = props;
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        setConfirmLoading(true);
        axios.delete(`user/accounts/${item.id}/${item.service}`)
            .then(({ data }) => {
                setList(p => p.filter(i => i.serviceId !== item.serviceId));
                message.success(data?.message || "Аккаунт отключен от учетоной записи");
            })
            .catch(e => {
                setOpen(false);
                message.error(axios.getError(e));
            })
            .then(() => {
                setConfirmLoading(false);
            });
    }

    return <List.Item
        className="profile-integrations-item"
        children={<Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
                avatar={<Avatar src={item?.avatar} />}
                title={<strong>{item.fullName}</strong>}
                description={item.email || item.login || item.serviceId}
            />
            <div className="h-5 text-right flex items-center">
                {item.serviceIcon &&
                    <img src={item.serviceIcon} alt={item.service} className="w-5 h-5 rounded-sm" />
                }
                <Popconfirm
                    title="Отключить аккаунт?"
                    // description="Вы действительно хотите отключить аккаунт от учетной записи?"
                    onConfirm={handleOk}
                    onCancel={() => setOpen()}
                    okText="Да"
                    cancelText="Нет"
                    open={open}
                    okButtonProps={{
                        loading: confirmLoading,
                    }}
                    cancelButtonProps={{
                        disabled: confirmLoading,
                    }}
                    children={<FontAwesomeIcon
                        icon={faTrash}
                        size="lg"
                        className={`ml-2 opacity-50 ${confirmLoading ? `` : `hover:opacity-100 cursor-pointer`}`}
                        title="Отключить интеграцию"
                        onClick={() => setOpen(true)}
                    />}
                />
            </div>
        </Skeleton>}
    />
}

export default Accounts;