import { useEffect } from "react";
import { Route, Routes } from "react-router";
import { Menu } from "../components/ChatGpt";
import ChatImages from "../components/ChatGpt/ChatImages";
import ChatCompletions from "../components/ChatGpt/ChatCompletions";
import Chat from "../components/ChatGpt/Chat";
import "../styles/chatgpt.css";

export const URL_PREFIX = "/chatgpt";

const ChatGpt = () => {

    useEffect(() => {
        return () => {
            window.Echo && window.Echo.leave(`OpenAI.${localStorage.getItem('kolgaev_user_id')}`);
        }
    }, []);

    return <div className="flex-grow flex justify-center" id="chatgpt">

        <div className="flex-grow flex justify-center">

            <div className="w-56 me-2">
                <Menu />
            </div>

            <div className="flex-grow relative px-3 max-w-3xl w-full bg-white">
                <div className="absolute inset-2 pb-3">
                    <Routes>
                        <Route path="/images" element={<ChatImages />} />
                        <Route path="/completions" element={<ChatCompletions />} />
                        <Route path="/chat" element={<Chat />} />
                        <Route path="/chat/:chatId" element={<Chat />} />
                        <Route path="*" element={<Chat />} />
                    </Routes>
                </div>
            </div>

        </div>

    </div>

}

export default ChatGpt;