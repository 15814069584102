import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Main/Main"
import NotFound from "./Errors/NotFound";
import App from "./Dashboard/App";
import { CRM, ChatGpt, Disk, Profile } from "../containers";
import Login from "./Auth/Login";
window.io = require('socket.io-client');

const DashBoard = () => {

    return <App>
        <Routes>
            <Route path="/crm/*" element={<CRM />} />
            <Route path="/chatgpt/*" element={<ChatGpt />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/disk/*" element={<Disk />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </App>
}

const Router = () => {

    return <Routes>
        <Route path="/" element={<Main />} />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<DashBoard />} />
    </Routes>
}

export default Router;