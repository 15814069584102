import { createRef, useEffect, useRef, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Chunks/Loading";
import { Col, Row } from "antd";
import File from "./File";
import { useParams } from "react-router";
import { useInView } from "react-intersection-observer";
import CreateFolder from "./Modals/CreateFolder";
import Show from "./Photos/Show";

const Files = () => {

    const dispatch = useDispatch();
    const { fetchFiles } = useActions();
    const { loading, files, meta } = useSelector(s => s.disk);
    const [page, setPage] = useState(1);
    const { folder } = useParams();
    const block = useRef();
    const full = createRef();
    full.current = Boolean(meta?.current_page && (meta.current_page >= meta.last_page));

    const { ref, inView } = useInView({
        threshold: 0.2,
    });

    useEffect(() => {
        return () => {
            dispatch({ type: "DISK_FETCH_FILES_LOADING", payload: false });
            dispatch({ type: "DISK_SET_FILES", payload: [] });
            dispatch({ type: "DISK_SET_FILES_META", payload: {} });
            setPage(1);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        !loading && inView && setPage(p => Boolean(p) ? p + 1 : 1);
    }, [loading, inView]);

    useEffect(() => {
        setPage(1);
        return () => {
            dispatch({ type: "DISK_SET_FILES", payload: [] });
            dispatch({ type: "DISK_SET_FILES_META", payload: {} });
        }
        // eslint-disable-next-line
    }, [folder]);

    useEffect(() => {
        !full?.current && !meta?.error && fetchFiles({ folder, page });
        // eslint-disable-next-line
    }, [page]);

    return <div className="max-w-7xl mx-auto" ref={block}>

        <Show />

        <Row gutter={[8, 8]}>

            {files.map(file => <Col
                xs={8}
                sm={3}
                lg={2}
                key={file.id}
                children={<File file={file} />}
            />)}

        </Row>

        {!loading && (files || []).length === 0 && !meta?.error && <div className="text-center opacity-50 my-5">
            <small>Файлов ещё нет</small>    
        </div>}

        <div ref={ref} className="h-1" />

        {<div className="mx-auto text-center mt-2" style={{ visibility: loading ? "visible" : "hidden" }}>
            <Loading />
        </div>}

        {meta?.error && <div className="text-red-500 text-center">
            <span>{meta?.error}</span>
        </div>}

        <CreateFolder />

    </div>
}

export default Files;