import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const NotFound = () => {

  return <div className="flex items-center justify-center absolute inset-0">

    <div className="-mt-12">

      <div className="text-center">
        <img
          className="mx-auto h-12 w-auto rounded-md"
          src="/logo192.jpg"
          alt="Your Company"
        />
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="text-yellow-500 me-3" />
        <h2 className="text-center text-3xl font-extrabold text-gray-900">404 Not Found</h2>
      </div>

      <p className="mt-0 text-center text-sm text-gray-600">Страница, которую Вы ищете, не найдена.</p>

      <div className="mt-8 text-center">
        <Link to="/" className="text-base font-medium text-yellow-500 hover:text-yellow-400">
          На главную &rarr;
        </Link>
      </div>

    </div>

  </div>
};

export default NotFound;