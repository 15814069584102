import { Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HeaderUserButton = () => {

    const { app } = useSelector(s => s);

    return app?.user && <Link
        to="/profile"
        children={<>
            <div className="mx-3">
                <Avatar
                    shape="square"
                    icon={<UserOutlined />}
                    src={app.user?.avatar}
                    className="flex items-center justify-center hover:opacity-80 cursor-pointer bg-slate-900"
                />
            </div>
            {/* <div className="me-3">
                <strong>{app.user.name}</strong>
            </div> */}
        </>
        }
    />
}

export default HeaderUserButton;