import { useEffect, useState } from "react";
import { axios } from "../../../../services";
import Content from "../../Content/Content";
import { Table } from "antd";
import Actions from "../../../../components/Crm/Telemetry/Devices/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

const columns = [
    {
        title: "",
        key: "action",
        render: data => <Actions {...data} />,
        align: "center",
    },
    {
        title: "Номер",
        dataIndex: "number",
        key: "number",
        render: (text) => <b>{text}</b>,
    },
    {
        title: "Наименование",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Тип",
        dataIndex: "type_name",
        key: "type_name",
    },
    {
        title: "Данные",
        dataIndex: "value",
        key: "value",
    },
];

const DevicesTable = () => {

    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const nav = useNavigate();

    useEffect(() => {

        setLoading(true);

        axios.get('crm/telemetry/devices')
            .then(({ data: { data } }) => {
                setError(null);
                setDevices(data);
            })
            .catch(e => {
                setError(axios.getError(e));
            })
            .then(() => {
                setLoading(false);
            });

    }, []);

    const data = devices.map(item => ({
        ...item,
        key: item.uuid,
        number: item.number || `#${item.id}`,
    }));

    return <Content
        title="Устройства"
        actions={!loading && <>
            <FontAwesomeIcon
                icon={faPlus}
                className="icon-link"
                onClick={() => nav(`${window.location.pathname}/create`)}
            />
        </>}
        loading={Boolean(loading)}
        children={<>

            {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <span className="font-medium">Ошибка!</span> {error}
            </div>}

            {!error && <Table
                columns={columns}
                dataSource={data}
            />}
        </>}
    />
}


export default DevicesTable;