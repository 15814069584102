import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const Client = props => <div className="flex items-center">
    <Avatar
        className="me-2"
        shape="square"
        src={props.avatar}
        icon={!props.avatar && <UserOutlined />}
    />
    <div>{props.name}</div>
</div>

export default Client;