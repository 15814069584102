import { faMessage, faImage, faCirclePlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import { URL_PREFIX } from "../../containers/ChatGpt";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { useEffect, useState } from "react";

const PARTS = [
    { key: "images", icon: faImage, title: "Изображение" },
    { key: "completions", icon: faMessage, title: "Вопрос-ответ" },
    // { key: "chat", icon: faComments, title: "Чат" },
]

const Menu = () => {

    const { chats } = useSelector(s => s.chatGpt);
    const { fetchChatsList } = useActions();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchChatsList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [chats]);

    return <div className="py-3">

        {PARTS.map(part => <NavLink
            className="chatgpt-menu-point"
            key={part.key}
            to={`${URL_PREFIX}/${part.key}`}
            end
            children={<>
                <FontAwesomeIcon
                    icon={part.icon}
                    className="me-2"
                />
                <strong>{part.title}</strong>
            </>}
        />)}

        <Link
            className="chatgpt-menu-point"
            to={`${URL_PREFIX}/chat`}
            onClick={() => setLoading(true)}
            children={<>
                <FontAwesomeIcon
                    icon={loading ? faSpinner : faCirclePlus}
                    className="me-2"
                    spin={loading}
                />
                <strong>Новый чат</strong>
            </>}
        />

        {chats.map((chat, key) => <NavLink
            className="chatgpt-menu-point"
            key={key}
            to={`${URL_PREFIX}/chat/${chat.chat_id}`}
            end
            children={<>
                <FontAwesomeIcon
                    icon={faComments}
                    className="me-2"
                />
                <span className={`${chat.first_message ? "opacity-100" : "opacity-40"}`}>
                    {chat.first_message || "Новая беседа..."}
                </span>
            </>}
        />)}

    </div>

}

export default Menu;