import { combineReducers } from "redux";
import { appReducer } from "./reducers/appReducer";
import { crmReducer } from "./reducers/crmReducer";
import { chatGptReducer } from "./reducers/chatGptReducer";
import { diskReducer } from "./reducers/diskReducer";

export const reducer = combineReducers({
    app: appReducer,
    crm: crmReducer,
    chatGpt: chatGptReducer,
    disk: diskReducer,
});