import Spinner from "../../Chunks/Spinner";
import Title from "./Title";

const Content = props => {

    const attributes = {
        title: props.title,
    }

    return <div
        {...attributes}
        className={`mx-auto px-3 py-2 w-full max-w-4xl ${props.className || ''}`}
        children={<>
            {props.title && <Title>
                <div className="flex items-center">
                    <span className="grow">{props.title}</span>
                    {props.actions && <span style={{ fontSize: 16 }}>{props.actions}</span>}
                </div>
            </Title>}
            {props.loading && <Spinner />}
            {!props.loading && props.children}
        </>}
    />
}

export default Content;