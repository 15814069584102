import { axios } from "../../services";
import * as TYPES from "../types/app";

export const API_TOKEN_ITEM_KEY = 'kolgaev_api_token';

const initialState = {
    user: null,
    token: null,
    loading: true,
    headerRight: null,
}

export const appReducer = (state = initialState, action) => {

    switch (action.type) {

        case TYPES.APP_REDUCER_STATE:
            return { ...state, ...(typeof action.payload == "object" ? action.payload : {}) }

        case TYPES.FETCH_CATCH:
            return {
                ...state,
                loading: false,
                error: action?.payload ? axios.getError(action.payload) : null,
                errors: action?.payload ? axios.getErrors(action?.payload) : {},
            }

        case (TYPES.FETCH_APP_START):
            return { ...state, loading: true, user: null }

        case TYPES.FETCH_APP_START_SUCCESS:
            action?.payload?.user && localStorage.setItem('kolgaev_user_id', action.payload.user.id);
            return { ...state, loading: false, ...(action?.payload ? action.payload : {}) }

        case TYPES.FETCH_APP_USER_LOGIN:
            action?.payload?.token && localStorage.setItem(API_TOKEN_ITEM_KEY, action.payload.token);
            action?.payload?.user && localStorage.setItem('kolgaev_user_id', action.payload.user.id);
            return { ...state, ...(action?.payload ? action.payload : {}) }

        case TYPES.SET_HEADER_RIGHT_COMPONENT:
            return { ...state, headerRight: action?.payload || null }

        case TYPES.SET_PROFILE_DATA:
            return { ...state, user: action?.payload }

        case TYPES.FETCH_APP_USER_LOGOUT:
            return { ...state, ...action?.payload }

        default:
            return { ...state };
    }

}