import { useNavigate, useParams } from "react-router";
import Content from "../../Content";
import { Alert, Button, Input, Select } from 'antd';
import { useEffect, useState } from "react";
import { axios } from "../../../../services";

const DeviceForm = props => {

    const { id, deviceId } = useParams();
    const { updateDevice } = props;
    const loading = !Boolean(props.device);
    const [device, setDevice] = useState(props.device || {});
    const [save, setSave] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const d = props.device || {};
        if (typeof d == "object" && typeof d.value_name == "undefined") {
            d.value_name = "value";
        }
        setDevice(d);
        setError(null);
        setErrors({});
    }, [props.device])

    const handleChange = (name, value) => setDevice(p => ({ ...p, [name]: value }));

    useEffect(() => {

        if (save) {

            const request = id === "create"
                ? axios.post(`crm/telemetry/devices`, device)
                : axios.put(`crm/telemetry/devices/${deviceId}`, device);

            request
                .then(({ data }) => {
                    setError(null);
                    setErrors({});
                    updateDevice(data);
                    navigate(`/crm/telemetry/devices/${data.id}`);
                })
                .catch(e => {
                    setError(axios.getError(e));
                    setErrors(axios.getErrors(e));
                })
                .then(() => {
                    setSave(false);
                })

        }

        // eslint-disable-next-line
    }, [save, device]);

    return <Content
        title={id === "create" ? "Новое устройство" : "Изменить устройство"}
        loading={loading}
        children={<>

            <div className="mb-4">
                <div className="mb-2"><strong>UUID</strong></div>
                <pre className="my-0">{device?.uuid || ""}</pre>
                {errors?.type && <small className="text-red-400">{errors.type}</small>}
            </div>

            <div className="mb-4">
                <div className="mb-2"><strong>Тип устройства <span className="text-red-600">*</span></strong></div>
                <div>
                    <Select
                        style={{ maxWidth: 500, width: "100%" }}
                        size="large"
                        options={device?.types || []}
                        placeholder="Выберите тип устройства"
                        name="type"
                        value={device?.type || null}
                        onChange={value => handleChange('type', value)}
                        disabled={save}
                        status={errors?.type ? "error" : null}
                    />
                </div>
                {errors?.type && <small className="text-red-400">{errors.type}</small>}
            </div>

            <div className="mb-4">
                <div className="mb-2"><strong>Переменная данных <span className="text-red-600">*</span></strong></div>
                <div>
                    <Input
                        style={{ maxWidth: 500, width: "100%" }}
                        size="large"
                        placeholder="Введите наименовнаие переменной"
                        name="value_name"
                        value={device?.value_name || ""}
                        onChange={e => handleChange(e.target.name, e.target.value)}
                        disabled={save}
                        status={errors?.value_name ? "error" : null}
                    />
                </div>
                {errors?.value_name
                    ? <small className="text-red-400">{errors.value_name}</small>
                    : <small className="text-gray-400">Переменная, в которой будут ожидаться данные, переданые запросом от устройства</small>
                }
            </div>

            <div className="mb-4">
                <div className="mb-2"><strong>Наименование</strong></div>
                <div>
                    <Input
                        style={{ maxWidth: 500, width: "100%" }}
                        size="large"
                        placeholder="Введите наименовнаие устройства"
                        name="name"
                        value={device?.name || ""}
                        onChange={e => handleChange(e.target.name, e.target.value)}
                        disabled={save}
                        status={errors?.name ? "error" : null}
                    />
                </div>
                {errors?.name && <small className="text-red-400">{errors.name}</small>}
            </div>

            <div className="mb-4">
                <div className="mb-2"><strong>Серийныный номер</strong></div>
                <div>
                    <Input
                        style={{ maxWidth: 500, width: "100%" }}
                        size="large"
                        placeholder="Введите серийный номер устройства"
                        name="number"
                        value={device?.number || ""}
                        onChange={e => handleChange(e.target.name, e.target.value)}
                        disabled={save}
                        status={errors?.number ? "error" : null}
                    />
                </div>
                {errors?.number && <small className="text-red-400">{errors.number}</small>}
            </div>

            <div className="mt-10 mb-4">
                {error && <Alert message={error} type="error" className="mb-3" showIcon style={{ maxWidth: 500 }} />}
                <Button onClick={() => setSave(true)} loading={save} type="primary" style={{ width: 170 }}>Сохранить</Button>
            </div>

        </>}
    />
}

export default DeviceForm;