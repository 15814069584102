import { Table } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { moment } from "../../../services";
import { useSelector } from "react-redux";
import { useActions } from "../../../hooks/useActions";
import { useEffect } from "react";
import Client from "../Components/Client";

import Actions from "./Actions";

const columns = [
    {
        title: "",
        key: "action",
        render: data => <Actions {...data} />,
        align: "center",
    },
    {
        title: 'Номер',
        dataIndex: 'number',
        key: 'number',
        // sorter: true,
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        // sorter: true,
        render: status => status && <div className="flex items-center">
            <div style={{ background: status?.color }} className="w-3 h-3 rounded me-2"></div>
            <span>{status?.name}</span>
        </div>
    },
    {
        title: 'Клиент',
        dataIndex: 'client',
        key: 'client',
        render: client => client && <Client {...client} />
    },
    {
        title: 'Тип',
        dataIndex: 'event_type',
        key: 'event_type',
        render: event_type => event_type?.name,
    },
    {
        title: 'Дата',
        dataIndex: 'event_at',
        key: 'event_at',
        // sorter: true,
        render: date => date && moment(date).format("DD MMM YYYY, HH:mm"),
    },
    {
        title: 'Сумма',
        dataIndex: 'amount',
        key: 'amount',
        // sorter: true,
    },
    {
        title: 'Примечание',
        dataIndex: 'notes',
        key: 'notes',
    },
];

const ApplicationsTable = () => {

    const { getApplications, setTableParams } = useActions();
    const { loading, applications, applicationsTableParams } = useSelector(s => s.crm);

    useEffect(() => {
        getApplications({ perPage: applicationsTableParams.pagination.pageSize });
        // eslint-disable-next-line
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {

        setTableParams({ pagination, filters, ...sorter });

        getApplications({
            page: pagination.current,
            perPage: pagination.pageSize,
            filters,
            ...(sorter?.column?.dataIndex ? { sorter: sorter.column.dataIndex, order: sorter.order } : {}),
        });
    };

    return <Table
        columns={columns}
        dataSource={applications.map((r, k) => ({ ...r, key: k }))}
        loading={loading && {
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        pagination={applicationsTableParams?.pagination || {}}
        size="middle"
        onChange={handleTableChange}
    />
}

export default ApplicationsTable;