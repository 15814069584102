import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "antd";

export const TextArea = ({ message, setMessage, sendMessage, loading, disabled }) => {

    return <div className="relative mt-3">
        <Input.TextArea
            placeholder="Отправить сообщение..."
            autoSize
            size="large"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            onPressEnter={e => {
                if (e.ctrlKey) return setMessage(e.target.value + "\n");
                e.preventDefault();
                Boolean(message) && sendMessage();
            }}
            className="pl-5 pr-8 rounded-2xl"
            disabled={Boolean(loading || disabled)}
        />
        {Boolean(message) && <FontAwesomeIcon
            icon={faPaperPlane}
            size="xl"
            className="text-gray-400 hover:text-gray-900 cursor-pointer absolute right-3 bottom-2"
            onClick={() => Boolean(message) && sendMessage()}
        />}
    </div>

}

export default TextArea;