import { useState } from "react";
import { axios } from "../../services";
import Button from "./Button";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Vkontakte = () => {

    const [loading, setLoading] = useState(false);
    const d = useDispatch();
    const { authServiceLoading } = useSelector(s => s.app);

    const fetchUrl = () => {

        setLoading(true);
        d({ type: "APP_REDUCER_STATE", payload: { authServiceLoading: true } });

        axios.post('/oauth/vkontakte')
            .then(({ data }) => {
                window.location.href = data.url;
            })
            .catch((e) => {
                notification.error({
                    message: 'Ошибка авторизации',
                    description: "Не получилось авторизоваться через ВКонтакте. Причина: " + axios.getError(e),
                })
            })
            .then(() => {
                setLoading(false);
                d({ type: "APP_REDUCER_STATE", payload: { authServiceLoading: false } });
            });
    }

    return <div className="relative" style={{ width: 40, height: 40 }}>
        <Button
            className="auth-button-vkontakte"
            onClick={() => fetchUrl()}
            disabled={loading}
        />
        {authServiceLoading && <div className="absolute inset-0 flex items-center justify-center z-10 bg-white bg-opacity-70">
            {loading &&<FontAwesomeIcon  icon="fas fa-spinner" spin size="lg" />}
        </div>}
    </div>
}

export default Vkontakte;