import { useEffect, useState } from "react";
import Loading from "../../Chunks/Loading";
import { useParams } from "react-router";
import { axios } from "../../../services";
import ApplicationPageData from "./ApplicationPageData";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedApplication } from "../../../store/actions/crmActions";
import ApplicationPageHistory from "./ApplicationPageHistory";

const ApplicationPage = () => {

    const { id } = useParams();
    const d = useDispatch();
    const crm = useSelector(s => s.crm);
    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState([]);

    const lead = crm.selected || {};
    const setLead = data => d(setSelectedApplication(data));

    useEffect(() => {

        id && axios.get(`crm/applications/${id}/edit`)
            .then(({ data }) => {
                setLead(data.data);
                setFields(data.fields);
            })
            .catch(e => {

            })
            .then(() => setLoading(false));

        return () => setLoading(true);

        // eslint-disable-next-line
    }, [id]);

    return <div>

        {loading && <div className="absolute inset-0 flex items-center justify-center">
            <Loading />
        </div>}

        {!loading && <div className="flex">
            <ApplicationPageData lead={lead} fields={fields} />
            <ApplicationPageHistory lead={lead} />
        </div>}

    </div>
}

export default ApplicationPage;