import { faClock, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "antd";
import { useEffect, useRef, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { moment } from "../../services";

const Message = props => {

    const { row } = props;
    // eslint-disable-next-line
    const [tik, setTik] = useState(new Date());
    const interval = useRef();

    useEffect(() => {
        interval.current = setInterval(() => setTik(new Date()), 15000);
        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return <div className="chat-message">

        <div className={`relative px-2 pt-1 pb-5 rounded-xl mt-2 whitespace-pre-wrap chat-message ${row.role === "assistant" ? "bg-slate-200 w-auto float-left rounded-bl-none" : "bg-sky-700 text-white w-auto float-right rounded-br-none"}`} style={{ minWidth: 200, maxWidth: row.image ? 500 : null }}>

            {row.image && <Image
                src={row.image_sm || row.image}
                preview={{
                    src: row.image,
                    mask: <div className="absolute inset-0 cursor-pointer" />,
                }}
            />}

            <ReactMarkdown
                children={row.message}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={vscDarkPlus}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}
            />

            <small className="absolute bottom-0 right-2 opacity-70">
                {row.created_at && moment(row.created_at).fromNow()}
                {!row.created_at && !row.error && <FontAwesomeIcon icon={faClock} size="sm" />}
                {row.error && <FontAwesomeIcon icon={faWarning} size="sm" className="ml-1 text-amber-500" />}
            </small>

        </div>

    </div>
}

export default Message;