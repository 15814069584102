import { Form, Modal, Skeleton, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useSelector } from "react-redux";
import { useActions } from "../../../hooks/useActions";
import { useEffect, useState } from "react";
import { axios } from "../../../services";
import FormItem from "../../Form/FormItem";
import Loading from "../../Parts/Loading";
import { useForm } from "antd/es/form/Form";

const ApplicationModal = () => {

    const { modalAddApplicationShow } = useSelector(s => s.crm);
    const { setShowModalAddApplication, crmAppendApplications } = useActions();

    const [loadingForm, setLoadingForm] = useState(true);
    const [fields, setFields] = useState([]);
    const [formdata, setFormdata] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const formRef = {};
    formRef.current = useForm()[0];


    useEffect(() => {

        if (modalAddApplicationShow) {

            setLoadingForm(true);

            const url = modalAddApplicationShow === true
                ? 'crm/applications/create'
                : `crm/applications/${modalAddApplicationShow}/edit`;

            axios.get(url)
                .then(({ data }) => {
                    setFormdata(data?.data || {});
                    setFields(data.fields);
                })
                .catch(e => {

                })
                .then(() => setLoadingForm(false));

        } else {
            setTimeout(() => {
                setFormdata({});
                setErrors({});
                setLoading(false);
                setLoadingForm(true);
            }, 300);
        }

    }, [modalAddApplicationShow]);

    const onFinish = () => {

        setLoading(true);

        const fetch = modalAddApplicationShow === true
            ? axios.post('crm/applications', formdata)
            : axios.put(`crm/applications/${modalAddApplicationShow}`, formdata);

        fetch
            .then(({ data }) => {
                setErrors({});
                crmAppendApplications(data);
                setShowModalAddApplication(false);
            })
            .catch(e => {

                const error = axios.getCatch(e);

                notification.error({
                    message: error?.statusText,
                    description: error?.message,
                });

                setErrors(error.errors || {});
            })
            .then(() => setLoading(false));
    }

    return <Modal
        open={modalAddApplicationShow}
        okText={modalAddApplicationShow === true ? "Создать" : "Сохранить"}
        cancelText="Отмена"
        cancelButtonProps={{
            disabled: loadingForm,
        }}
        okButtonProps={{
            disabled: loadingForm,
        }}
        onCancel={() => setShowModalAddApplication(false)}
        onOk={onFinish}
        children={<>

            {loading && <Loading />}

            <Title level={3} className="mt-0">{modalAddApplicationShow !== true ? 'Изменить заявку' : 'Новая заявка'}</Title>

            {loadingForm && <div className="my-6">
                <Skeleton.Input active block className="mb-1" />
                <Skeleton.Input active block className="mb-1" />
                <Skeleton.Input active block className="mb-1" />
                <Skeleton.Input active block className="mb-1" />
                <Skeleton.Input active block className="mb-1" />
                <Skeleton.Input active block className="mb-1" />
            </div>}

            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                className="my-6"
                hidden={loadingForm}
                children={<>
                    {fields.map(row => <FormItem
                        key={row.id}
                        {...row}
                        value={Boolean(formdata[row.name]) ? formdata[row.name] : null}
                        validateStatus={Boolean(errors[row.name]) ? "error" : null}
                        help={Boolean(errors[row.name]) ? errors[row.name] : null}
                        formdata={formdata}
                        setFormdata={setFormdata}
                    />)}
                </>}
            />

        </>}
    />
}

export default ApplicationModal;