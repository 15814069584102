import { message } from "antd";
import * as TYPES from "../types/crm";
import { axios } from "../../services";

export const setShowModalAddClient = show => ({ type: TYPES.SET_SHOW_MODAL_ADD_CLIENT, payload: show });
export const setShowModalAddApplication = show => ({ type: TYPES.SET_SHOW_MODAL_ADD_APPLICATION, payload: show });

export const crmClientAppendNew = data => {
    message.success("Новый клиент добавлен");
    return { type: TYPES.CRM_CLIENT_APPEND_NEW_CLIENT, payload: data }
}

export const crmAppendApplications = data => ({ type: TYPES.CRM_APPLICATION_APPEND, payload: data });

export const setSelectedApplication = data => ({ type: TYPES.CRM_APPLICATION_SELECTED, payload: data });

export const getApplications = (params) => {

    return async dispatch => {

        dispatch({ type: TYPES.CRM_APPLICATIONS_FETCH_LOADING, payload: true });

        try {

            const response = await axios.get(`crm/applications`, { params });

            dispatch({ type: TYPES.CRM_APPLICATIONS_FETCH_SUCCESS, payload: response?.data?.data || [] });

            const pagination = {
                total: response?.data?.meta?.total || null,
                current: response?.data?.meta?.current_page || 1,
            }
            dispatch({ type: TYPES.CRM_APPLICATION_TABLE_PARAMS_PAGINATION, payload: pagination });

        } catch (error) {
            dispatch({ type: TYPES.CRM_APPLICATIONS_FETCH_ERROR, payload: error });
        }

        dispatch({ type: TYPES.CRM_APPLICATIONS_FETCH_LOADING, payload: false });
    }

}

export const setTableParams = data => ({ type: TYPES.CRM_APPLICATION_TABLE_PARAMS, payload: data });
