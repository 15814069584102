import { Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";

const Actions = ({ id, className }) => {

    const navigate = useNavigate();

    const items = [];

    items.unshift({
        key: 0,
        label: "Подробнее",
        icon: <FontAwesomeIcon icon={faEye} />,
        onClick: () => navigate(`/crm/telemetry/devices/${id}`),
    });

    items.push({
        key: 1,
        label: "Изменить",
        icon: <FontAwesomeIcon icon={faPencil} />,
        onClick: () => navigate(`/crm/telemetry/devices/edit/${id}`),
    });

    return items.length > 0 && <Dropdown menu={{ items }} className={className}>
        <span onClick={(e) => e.preventDefault()} className="cursor-pointer text-black hover:text-gray-700">
            <FontAwesomeIcon icon={faEllipsisVertical} />
        </span>
    </Dropdown>
}

export default Actions;