import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useDispatch, useSelector } from "react-redux";
import { appendMessage, setChatFirstMessage } from "../../store/actions/chatGptActions";
import { axios, uuidv4 } from "../../services";
import Message from "./Message";
import TextArea from "./TextArea";
import Loading from "../Chunks/Loading";

const ChatCompletions = () => {

    const { error, loading, messages } = useSelector(s => s.chatGpt);
    const { chatGptLoading } = useActions();
    const isError = Boolean(typeof error == "object" && Object.keys(error).length > 0);

    useEffect(() => {
        chatGptLoading('completions');
        // eslint-disable-next-line
    }, []);

    return <div className="flex flex-col items-stretch h-full">

        {loading && <div className="absolute inset-0 flex items-center justify-center">
            <Loading />
        </div>}

        {!loading && messages.length === 0 && !isError && <div className="absolute inset-0 flex items-center justify-center opacity-30">
            <small>Сообщений ещё нет...</small>
        </div>}

        {!loading && isError && <div className="absolute inset-0 flex items-center justify-center opacity-80">
            <small className="text-red-500">{error?.message || "Ошибка"}</small>
        </div>}

        <div className="grow flex flex-col-reverse overflow-y-auto px-1">
            {!loading && messages.map((row, i) => <Message key={i} row={row} />)}
        </div>

        <TextAreaComponent
            loading={loading}
            error={isError}
        />

    </div>
}

const TextAreaComponent = ({ loading, error }) => {

    const [message, setMessage] = useState("");
    const d = useDispatch();

    const sendMessage = () => {

        const data = {
            message,
            uuid: uuidv4(),
        }

        d(appendMessage(data));

        setMessage("");

        const formdata = new FormData();
        formdata.append('message', data.message);
        formdata.append('uuid', data.uuid);

        axios.post('openai/completions/send', formdata)
            .then(({ data }) => {

                d(appendMessage(data.message));
                d(setChatFirstMessage(data.message));

                const eventSource = new EventSource(
                    `${axios.defaults.baseURL}openai/completions/streamed?data=${data.streamData}`
                );

                eventSource.onmessage = (e) => {

                    let message = JSON.parse(e.data);

                    d(appendMessage(message));

                    if (message.id) {
                        eventSource.close();
                    }
                };
            })
            .catch(e => {
                d(appendMessage({ ...data, error: true, created_at: new Date() }));
            });
    }

    return <TextArea
        message={message}
        setMessage={setMessage}
        sendMessage={sendMessage}
        loading={loading}
        disabled={error}
    />
}

export default ChatCompletions;