import { message } from 'antd';

const useCopy = () => {

    const copyToClipboard = (text, alert = null) => {
        var input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        if (typeof alert == "string") {
            message.success(alert)
        }
    };

    return { copyToClipboard };
};

export default useCopy;