import { Progress } from "antd";
import file from "../Icons/file.svg";

const UploadFile = props => {

    const { data, type } = props;

    return <div style={{ width: 80 }}>

        {data && <div className="relative">

            <div
                className="text-center flex items-center justify-center"
                style={{
                    width: 80,
                    height: 80,
                    margin: "0 !important",
                    opacity: type === "before" ? "0.5" : "1"
                }}
                children={
                    <img
                        src={file}
                        alt={`file_${data?.key || 0}`}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                        className="rounded-sm"
                    />
                }
            />

            {(type === "current" || type === "after") && <div
                className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
                children={<Progress type="circle" percent={data?.percent || 100} size={40} />}
            />}

        </div>}

    </div>
}

export default UploadFile;