export const DISK_FETCH_FILES_LOADING = "DISK_FETCH_FILES_LOADING";
export const DISK_SET_FOLDER = "DISK_SET_FOLDER";
export const DISK_SET_FILES_META = "DISK_SET_FILES_META";
export const DISK_SET_FILES = "DISK_SET_FILES";
export const DISK_APPEND_FILES = "DISK_APPEND_FILES";
export const DISK_SET_FILE = "DISK_SET_FILE";
export const DISK_APPEND_FOLDER = "DISK_APPEND_FOLDER";

export const DISK_MODAL_SHOW_CREATE_FOLDER = "DISK_MODAL_SHOW_CREATE_FOLDER";

export const DISK_UPLOAD_SET_LIST = "DISK_UPLOAD_SET_LIST";
export const DISK_UPLOAD_SET_FILE = "DISK_UPLOAD_SET_FILE";