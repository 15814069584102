import { faFolderPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActions } from "../../../hooks/useActions";
import { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Progress } from "antd";
import UploadFiles from "../Modals/UploadFiles";

const RightHeader = () => {

    const { setShowCreateFolder, setUploadList } = useActions();
    const uploadRef = useRef(null);
    const { uploadList, uploadedPercent } = useSelector(s => s.disk)
    const isUpload = (uploadList || []).length > 0;

    const onChange = useCallback(data => {
        setUploadList(data.target.files);
        // eslint-disable-next-line
    }, []);

    return <div className="flex">

        <div className="relative">
            <FontAwesomeIcon
                icon={faUpload}
                size="xl"
                className={`mx-2 opacity-${isUpload ? "90" : "60"} hover:opacity-90 cursor-pointer`}
                title="Загрузить файл"
                onClick={() => uploadRef?.current && uploadRef.current.click()}
                fade={isUpload}
            />

            <input
                type="file"
                ref={uploadRef}
                multiple
                className="hidden"
                onChange={onChange}
            />

            {isUpload && <Progress
                percent={uploadedPercent}
                showInfo={false}
                className="absolute left-0 right-0"
                size={["100%", 4]}
                style={{ bottom: -23 }}
                status="active"
            />}

            <UploadFiles />
        </div>

        <FontAwesomeIcon
            icon={faFolderPlus}
            size="xl"
            className="mx-2 opacity-60 hover:opacity-90 cursor-pointer"
            title="Создать папку"
            onClick={() => setShowCreateFolder(true)}
        />

    </div>
}

export default RightHeader;