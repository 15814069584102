import { Button, Form, Input, message, notification } from "antd";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axios } from "../../services";
import InputMask from "antd-mask-input";
import ProfileContents from "../../components/Profile/ProfileContents";
import { SET_PROFILE_DATA } from "../../store/types/app";

const sortable = obj => Object.fromEntries(Object.entries(obj).sort());

const InputPhone = props => <InputMask {...props} />;

const Edit = () => {

    const formRef = useRef(null);
    const dispatch = useDispatch();

    const { user: { email, name, patronymic, phone, surname, username } } = useSelector(s => s.app);
    const [control, setControl] = useState(JSON.stringify(
        sortable({ email, name, patronymic, phone, surname, username })
    ));
    const [formdata, setFormdata] = useState(
        sortable({ email, name, patronymic, phone, surname, username })
    );
    const [errors, setErrors] = useState({});
    const changed = control !== JSON.stringify(formdata);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        formRef.current?.setFieldsValue(formdata);
        // eslint-disable-next-line
    }, []);

    const onChange = e => {
        setFormdata(p => {
            let name = e?.target?.name,
                value = e?.target?.value || null;
            return sortable({ ...p, [name]: value })
        });
    }

    const onFieldsChange = data => {
        let name = data[0]?.name[0] || null,
            value = data[0]?.value || null;
        if (name === "phone" && value === "+7 (___) ___-____") {
            value = null;
        }
        setFormdata(p => sortable({ ...p, [name]: value }));
    }

    const onFinish = values => {

        setLoading(true);

        axios.put('user/update', values)
            .then(({ data }) => {
                setControl(JSON.stringify(sortable(values)));
                setErrors({});
                dispatch({ type: SET_PROFILE_DATA, payload: data });
                message.success({ content: "Данные обновлены" });
            })
            .catch(e => {
                setErrors(axios.getErrors(e));
                notification.error({
                    title: "Ошибка",
                    description: axios.getError(e),
                })
            })
            .then(() => {
                setLoading(false);
            });
    }

    return <ProfileContents
        title="Личные данные"
        content={<Form
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
                maxWidth: 600,
            }}
            className="relative"
            onChange={onChange}
            onFieldsChange={onFieldsChange}
            errors={errors}
            initialValues={formdata}
            children={<>

                <div className="relative">

                    {loading && <div className="absolute inset-0 z-10 flex justify-center items-center bg-white bg-opacity-50">
                        <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin size="2x" />
                    </div>}

                    <Form.Item
                        name="name"
                        label="Имя"
                        rules={[
                            { required: true },
                        ]}
                        children={<Input name="name" />}
                        validateStatus={errors.name ? 'error' : false}
                        help={errors.name ? errors.name.join(" ") : null}
                    />
                    <Form.Item
                        name="surname"
                        label="Фамилия"
                        children={<Input name="surname" />}
                        validateStatus={errors.surname ? 'error' : false}
                        help={errors.surname ? errors.surname.join(" ") : null}
                    />
                    <Form.Item
                        name="patronymic"
                        label="Отчество"
                        children={<Input name="patronymic" />}
                        validateStatus={errors.patronymic ? 'error' : false}
                        help={errors.patronymic ? errors.patronymic.join(" ") : null}
                    />
                    <Form.Item
                        name="email"
                        label="Email"
                        children={<Input name="email" />}
                        rules={[
                            { required: true },
                        ]}
                        validateStatus={errors.email ? 'error' : false}
                        help={errors.email ? errors.email.join(" ") : null}
                    />
                    <Form.Item
                        name="username"
                        label="Логин"
                        children={<Input name="username" />}
                        validateStatus={errors.username ? 'error' : false}
                        help={errors.username ? errors.username.join(" ") : null}
                    />
                    <Form.Item
                        name="phone"
                        label="Телефон"
                        children={<InputPhone mask="+7 (000) 000-0000" />}
                        validateStatus={errors.phone ? 'error' : false}
                        help={errors.phone ? errors.phone.join(" ") : null}
                    />

                </div>

                <Button
                    type="primary"
                    htmlType="submit"
                    children="Сохранить"
                    disabled={!changed || loading}
                />

            </>}
        />}
    />
}

export default Edit;