import { useSelector } from "react-redux";
import AvatarEdit from "../../components/Profile/AvatarEdit";
import { Button, Col, Row, Typography } from 'antd';
import "./profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActions } from "../../hooks/useActions";
import { Route, Routes, useNavigate } from "react-router";
import Edit from "./Edit";
import Accounts from "./Accounts";

const Profile = () => {
    return <div className="text-center w-full max-w-lg mx-auto mt-8">
        <Routes>
            <Route path="/" element={<ProfileMain />} />
            <Route path="edit" element={<Edit />} />
            <Route path="accounts" element={<Accounts />} />
            <Route path="*" element={<ProfileMain />} />
        </Routes>
    </div>
}

const ProfileMain = () => {

    const { app } = useSelector(s => s);
    const { user, isFetchLogout } = app;
    const { fetchLogout } = useActions();
    const navigate = useNavigate();

    return user && <div>

        <AvatarEdit />

        <Typography.Title level={2}>{user?.name}</Typography.Title>

        <Row>
            <Col span={12} className="p-1">
                <Button
                    onClick={() => navigate("/profile/edit")}
                    children="Личные данные"
                    block
                />
            </Col>
            <Col span={12} className="p-1">
                <Button
                    onClick={() => navigate("/profile/accounts")}
                    children="Аккаунты"
                    block
                />
            </Col>
        </Row>

        <Button
            className="opacity-60 mt-16"
            onClick={() => fetchLogout(() => {
                navigate("/");
            })}
            disabled={isFetchLogout}
            loading={isFetchLogout}
            icon={<FontAwesomeIcon icon="fas fa-sign-out-alt" />}
            children={<span className="px-2">Выход</span>}
        />

    </div>

}

export default Profile;