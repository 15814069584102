import { axios, queryString } from "../../services";
import * as TYPES from "../types/chatgpt";

/**
 * Загрузка чата
 */
export const chatGptLoading = (type, data = {}) => {

    return async dispatch => {

        dispatch({ type: TYPES.CHATGPT_MAIN_LOADING, payload: true });
        dispatch({ type: TYPES.CHATGPT_CATCH, payload: null });

        try {
            const response = await axios.get(`openai/${type}?${queryString(data)}`);

            (typeof response?.data?.messages == "object" ? response.data.messages : [])
                .slice()
                .reverse()
                .forEach(r => dispatch({ type: TYPES.CHATGPT_APPEND_MESSAGE, payload: r }));

            if (data?.chatId !== response?.data?.chatId) {
                dispatch({ type: TYPES.CHATGPT_SET_CHAT_ID, payload: response?.data?.chatId });
            }

            if (response?.data?.chat) {
                dispatch({ type: TYPES.OPENAI_SET_CHAT_LIST, payload: response.data.chat });
            }

            window.Echo && window.Echo.private(`OpenAI.${localStorage.getItem('kolgaev_user_id')}`)
                .listen('OpenAI\\NewMessage', data => {
                    dispatch({ type: TYPES.CHATGPT_APPEND_MESSAGE, payload: data.openAiImage });
                });

        } catch (e) {
            dispatch({ type: TYPES.CHATGPT_CATCH, payload: e });
        }

        dispatch({ type: TYPES.CHATGPT_MAIN_LOADING, payload: false });
        dispatch({ type: TYPES.CHATGPT_MAIN_LOADED, payload: true });
    }
}

export const appendMessage = data => ({ type: TYPES.CHATGPT_APPEND_MESSAGE, payload: data });
export const setChatFirstMessage = data => ({ type: TYPES.OPENAI_SET_CHAT_FIRST_MESSAGE, payload: data });

export const CHAT_LIST_COUNT = 10;

/**
 * Загрузка списка последних бесед
 * 
 * @param {*} formdata 
 * @param {*} setLoading 
 * @param {*} success 
 * @returns 
 */
export const fetchChatsList = (setLoading = null, success = null) => {

    return async dispatch => {

        typeof setLoading == "function" && setLoading(true);

        try {

            const response = await axios.get('openai/getChats', {
                params: { count: CHAT_LIST_COUNT }
            });
            typeof success == "function" && success(response.data);

            (typeof response?.data == "object" ? response.data : [])
                .slice()
                .reverse()
                .forEach(r => dispatch({ type: TYPES.OPENAI_SET_CHAT_LIST, payload: r }));
        } catch (e) {
            console.log(e);
        }

        typeof setLoading == "function" && setLoading(false);
    }
}