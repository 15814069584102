import { Timeline, Typography } from "antd";
import { axios, moment } from "../../../services";
import { useEffect, useState } from "react";
import Modified from "./History/Modified";

const { Title } = Typography;

const ApplicationPageHistory = props => {

    const { lead } = props;
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {

        setLoading(true);

        axios.get(`crm/applications/${lead.id}/history`)
            .then(({ data }) => {
                setItems(data);
            })
            .catch(e => {

            })
            .then(() => {
                setLoading(false);
                setLoaded(true);
            });

        // eslint-disable-next-line
    }, [JSON.stringify(lead || {})]);

    useEffect(() => {
        return () => {
            setLoading(true);
            setLoaded(false);
        }
    });

    return <div className="max-w-2xl w-full mx-2">

        <Title level={5}>История</Title>

        <Timeline
            mode="left"
            reverse
            className="mt-6"
            items={items.map(r => {

                let color = "gray";

                if (r.event === "created") color = "green";
                else if (r.event === "updated") color = "blue";

                return {
                    color,
                    children: <>
                        {r.event === "created" && <div>
                            <div>Заявка создана</div>
                        </div>}
                        {r.event === "updated" && <div>
                            <div>Обновление данных</div>
                        </div>}
                        <div className="opacity-50">
                            <small>{moment(r.created_at).format("DD.MM.YYYY в HH:mm")}</small>
                        </div>
                        {r.event === "updated" && <div>
                            <Modified rows={r.modified} />
                        </div>}
                    </>,
                }
            })}
        />

    </div>
}

export default ApplicationPageHistory;