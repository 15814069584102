import * as TYPES from "../types/crm";

const initialState = {
    modalAddClientShow: false,
    modalAddApplicationShow: false,
    clients: [],
    loading: true,
    applications: [],
    selected: {},
    applicationsTableParams: {
        pagination: {
            current: 1,
            pageSize: 30,
        }
    },
}

export const crmReducer = (state = initialState, action) => {

    switch (action.type) {

        case TYPES.SET_SHOW_MODAL_ADD_CLIENT:
            return { ...state, modalAddClientShow: action.payload }

        case TYPES.CRM_CLIENT_APPEND_NEW_CLIENT:
            return { ...state, clients: [action.payload, ...state.clients] }

        case TYPES.SET_SHOW_MODAL_ADD_APPLICATION:
            return { ...state, modalAddApplicationShow: action.payload }

        case TYPES.CRM_APPLICATION_APPEND:

            let index = state.applications.findIndex(r => r.id === action?.payload?.id);

            let selected = (state.selected?.id && state.selected?.id === action?.payload?.id)
                ? action?.payload : state.selected;

            if (index >= 0) {
                state.applications.splice(index, 1, action.payload);
                return { ...state, applications: state.applications, selected }
            } else {
                return { ...state, applications: [action.payload, ...state.applications], selected }
            }

        case TYPES.CRM_APPLICATION_SELECTED:
            return { ...state, selected: action.payload }

        case TYPES.CRM_APPLICATIONS_FETCH_LOADING:
            return { ...state, loading: action.payload }

        case TYPES.CRM_APPLICATIONS_FETCH_SUCCESS:
            return { ...state, applications: action.payload }

        case TYPES.CRM_APPLICATION_TABLE_PARAMS:
            return { ...state, applicationsTableParams: action.payload }

        case TYPES.CRM_APPLICATION_TABLE_PARAMS_PAGINATION:
            return {
                ...state,
                applicationsTableParams: {
                    ...state.applicationsTableParams,
                    pagination: {
                        ...(state?.applicationsTableParams?.pagination || {}),
                        ...action.payload,
                    }
                }
            }

        default:
            return { ...state };
    }

}