import { Input } from "antd";

const String = props => {

    const { name, placeholder } = props;
    const { formdata, setFormdata } = props;
    const value = formdata[name] || null;

    return <Input
        placeholder={placeholder}
        value={value}
        onChange={e => setFormdata(p => ({ ...p, [name]: e?.target?.value }))}
    />
}

export default String;