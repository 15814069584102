import { useParams } from "react-router";
import DevicesTable from "../../../components/Crm/Telemetry/Devices/DevicesTable";
import Device from "../../../components/Crm/Telemetry/Devices/Device";

const Devices = () => {

    const { id } = useParams();

    return <>
        {id ? <Device /> : <DevicesTable />}
    </>
}

export default Devices;