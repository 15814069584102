import { useLocation, useNavigate } from "react-router-dom";
import icons from "./Icons";
import { Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTextSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";

const File = props => {

    const { file } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [items, setItems] = useState();
    const { downloadFile } = useActions();
    const { pathname, search } = location;

    const icon = file.is_image && file?.photos?.sm
        ? file?.photos?.sm
        : (icons[file?.icon] ?? icons.file);

    useEffect(() => {

        const items = [];

        file?.is_rename && items.push({
            key: 0,
            label: 'Переименовать',
            icon: <FontAwesomeIcon icon={faTextSlash} className="opacity-80" />,
        });

        items.push({
            key: 1,
            label: 'Скачать',
            icon: <FontAwesomeIcon icon={faDownload} className="opacity-80" />,
            onClick: () => downloadFile(file),
        });

        file?.is_delete && items.push({
            key: 2,
            label: 'Удалить',
            icon: <FontAwesomeIcon icon={faTrash} className="opacity-80" />,
        });

        setItems(items);
        // eslint-disable-next-line
    }, [file]);

    const navClick = useCallback(() => {
        if (file.is_dir) {
            return navigate("/disk/" + file.link);
        } else if (file.is_image) {
            const newSearch = new URLSearchParams(search);
            newSearch.set('photo', file.link);
            return navigate(`${pathname}?${newSearch.toString()}`);
        }
        // eslint-disable-next-line
    }, []);

    return <Dropdown menu={{ items }} trigger={['contextMenu']}>

        <div onClick={navClick}
            className="h-32 bg-slate-50 hover:bg-slate-200 rounded-md cursor-pointer py-2 px-1 text-center flex flex-col items-center"
        >

            <div className="text-center flex items-center justify-center" style={{ width: 80, height: 80, margin: "0 !important" }}>
                <img src={icon} alt={`file_${file.id}`} style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                }} className="rounded-sm" />
            </div>

            <div className="max-w-full mt-1 px-1 whitespace-pre-line text-center overflow-hidden text-ellipsis break-words line-clamp-2" style={{ lineHeight: "100%" }}>
                {file.name}
            </div>
        </div>
    </Dropdown>
}

export default File;