import { axios } from "../../services";
import * as TYPES from "../types/disk";
import { message, notification } from 'antd';
import { Buffer } from 'buffer';

const UPLOAD_CHUNK_SIZE = 4242880;

export const fetchFiles = (params) => {

    return async dispatch => {

        dispatch({ type: TYPES.DISK_SET_FOLDER, payload: params?.folder || null });
        dispatch({ type: TYPES.DISK_FETCH_FILES_LOADING, payload: true });

        try {
            const response = await axios.get('disk/files', { params: params });
            dispatch({ type: TYPES.DISK_APPEND_FILES, payload: response?.data?.data || [] });
            dispatch({ type: TYPES.DISK_SET_FILES_META, payload: response?.data?.meta || {} });
        } catch (e) {
            dispatch({ type: TYPES.DISK_SET_FILES_META, payload: {
                error: axios.getError(e),
            } });
        }

        dispatch({ type: TYPES.DISK_FETCH_FILES_LOADING, payload: false });
    }
}

export const setShowCreateFolder = show => (
    { type: TYPES.DISK_MODAL_SHOW_CREATE_FOLDER, payload: show }
);

export const appendFolder = data => (
    { type: TYPES.DISK_APPEND_FOLDER, payload: data }
);

export const downloadFile = file => {

    return async dispatch => {

        let key = Buffer.from(JSON.stringify(file)).toString("base64");

        message.loading({
            content: <span>Проверка файла <strong>{file.name}</strong></span>,
            duration: 120,
            key: key,
        });

        axios.get(`disk/download/${file.link}`)
            .then(response => {
                notification.success({
                    message: file.name,
                    description: response.data.message,
                    duration: 25,
                });

                if (response.data?.url) {

                    setTimeout(() => {

                        let file_name = response.data?.file_name
                            ? response.data.file_name
                            : `${file.name}${file.extension ? `.${file.extension}` : ``}`;

                        const link = document.createElement('a');
                        link.href = response.data.url;
                        link.target = "_blank";
                        link.setAttribute('download', file_name);
                        document.body.appendChild(link);
                        link.click();

                    }, 500);
                }
            })
            .catch(e => {
                notification.error({
                    message: "Ошибка загрузки",
                    description: axios.getError(e),
                    duration: 6,
                });
            })
            .then(() => {
                message.destroy(key);
            });
    }
}

export const setUploadList = data => {

    return async (dispatch, getState) => {

        dispatch({ type: TYPES.DISK_UPLOAD_SET_LIST, payload: data });

        const { disk } = getState();
        const { uploadFilesList, uploadList } = disk;

        if (typeof uploadList == "object") {

            for (let i in uploadList) {

                let item = uploadList[i],
                    file = uploadFilesList[i];

                item.status = "upload";
                dispatch({ type: TYPES.DISK_UPLOAD_SET_FILE, payload: { key: i, item } });

                while (item.uploaded < item.size && item.status !== "error") {

                    let chunk = await getChunkFile(i, file, item, dispatch, getState);

                    await axios.post('disk/upload', { ...item, chunk, href: window.location.href })
                        // eslint-disable-next-line
                        .then(({ data }) => {
                            item = {
                                ...item,
                                path: data.path || null,
                                uploaded: data.uploaded || 0,
                                fileName: data?.fileName,
                                chunkOffset: item.chunkOffset + UPLOAD_CHUNK_SIZE,
                            };

                            if (item.uploaded >= item.size) {
                                item.status = "done";
                            }
                            console.log(data);
                            dispatch({ type: TYPES.DISK_UPLOAD_SET_FILE, payload: { key: i, item } });
                        })
                        // eslint-disable-next-line
                        .catch(e => {
                            item.status = "error";
                            dispatch({ type: TYPES.DISK_UPLOAD_SET_FILE, payload: { key: i, item } });
                        });
                }
            }
        }
    }
}

const getChunkFile = async (key, file, item, dispatch, getState) => {

    return new Promise((resolve, reject) => {

        let reader = new FileReader();

        // Вывод ошибки чтения файла
        reader.onerror = event => {

            item.status = "error";

            dispatch({ type: TYPES.DISK_UPLOAD_SET_FILE, payload: { key, item } });
            console.error("Failed to read file!\n" + reader.error);

            reader.abort();
            resolve(false);
        }

        reader.onloadend = (evt) => {

            let base64 = String(reader.result),
                len = base64.indexOf(',');

            base64 = len > 0 ? base64.substring(len + 1) : base64;

            resolve(base64);
        };

        let blob = file.slice(item.chunkOffset, item.chunkOffset + UPLOAD_CHUNK_SIZE);
        reader.readAsDataURL(blob);
    });

}
