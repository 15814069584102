import { useEffect } from "react";
import { useNavigate } from "react-router";

const Login = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
        // eslint-disable-next-line
    }, []);

    return null;
}

export default Login;