import * as AppActionCreators from "./appActions";
import * as CrmActionCreators from "./crmActions";
import * as ChatGptActionCreators from "./chatGptActions";
import * as DiskActionCreators from "./diskActions";

export const actions = {
    ...AppActionCreators,
    ...CrmActionCreators,
    ...ChatGptActionCreators,
    ...DiskActionCreators,
}

export default actions;