import Vkontakte from "./Vkontakte";
import Yandex from "./Yandex";
import "./style.css";

const Services = () => {
    return <div>
        <h4 className="text-center">Войти с помощью</h4>
        <div className="flex justify-center auth-services-btns">
            <Yandex />
            <Vkontakte />
        </div>
    </div>
}

export default Services;