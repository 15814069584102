import { Dropdown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { useActions } from "../../../hooks/useActions";

const Actions = ({ id, className, noShow }) => {

    const navigate = useNavigate();
    const { setShowModalAddApplication } = useActions();

    const items = [
        {
            key: 1,
            label: "Изменить",
            icon: <FontAwesomeIcon icon={faPencil} />,
            onClick: () => setShowModalAddApplication(id),
        }
    ]

    if (!noShow) {
        items.unshift({
            key: 0,
            label: "Подробнее",
            icon: <FontAwesomeIcon icon={faEye} />,
            onClick: () => navigate(`/crm/applications/${id}`),
        })
    }

    return <Dropdown menu={{ items }} className={className}>
        <span onClick={(e) => e.preventDefault()} className="cursor-pointer text-black hover:text-gray-700">
            <FontAwesomeIcon icon={faEllipsisVertical} />
        </span>
    </Dropdown>
}

export default Actions;