import { Typography } from "antd";
import Breadcrumb from "../Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const ProfileContents = props => {

    const breadcrumb = [
        { href: "/", title: <FontAwesomeIcon icon={faHome} /> },
        { href: "/profile", title: "Профиль" },
    ];

    return <>

        <Breadcrumb
            items={breadcrumb}
            className="mb-2"
        />

        <Typography.Title level={3} className="text-left mt-2">{props.title}</Typography.Title>

        <div className="content-card mt-6 relative">
            {props.content}
        </div>

    </>
}

export default ProfileContents;