const Button = props => {

    const buttonProps = {
        ...props,
        className: typeof props.className == "string"
            ? "auth-button " + props.className
            : "auth-button",
        type: "button",
    }

    return <button {...buttonProps} />
}

export default Button;