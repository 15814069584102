import { faList, faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "antd"
import { useActions } from "../../../hooks/useActions";
import { useEffect } from "react";
// import { useSelector } from "react-redux";

const Right = () => {

    const { setShowModalAddClient, setShowModalAddApplication } = useActions();
    // console.log(useSelector(s => s.crm));

    const items = [
        {
            label: 'Добавить клиента',
            key: 'modalAddClientShow',
            icon: <FontAwesomeIcon icon={faUser} />,
            onClick: () => setShowModalAddClient(true),
        },
        {
            label: 'Создать заявку',
            key: 'modalAddApplicationShow',
            icon: <FontAwesomeIcon icon={faList} />,
            onClick: () => setShowModalAddApplication(true),
        },
        // {
        //     label: '3rd menu item',
        //     key: '3',
        //     // icon: <UserOutlined />,
        //     danger: true,
        // },
        // {
        //     label: '4rd menu item',
        //     key: '4',
        //     // icon: <UserOutlined />,
        //     danger: true,
        //     disabled: true,
        // },
    ];

    useEffect(() => {

        var themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
        var themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

        // Change the icons inside the button based on previous settings
        if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            themeToggleLightIcon.classList.remove('hidden');
        } else {
            themeToggleDarkIcon.classList.remove('hidden');
        }

        return () => {

        }

    }, []);

    const themeToggle = () => {

        let themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
        let themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

        // toggle icons inside button
        themeToggleDarkIcon.classList.toggle('hidden');
        themeToggleLightIcon.classList.toggle('hidden');

        // if set via local storage previously
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }

            // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }
    }

    return <div className="flex">

        <Dropdown
            menu={{ items }}
            placement="bottomRight"
            arrow
            trigger={['click']}
            children={<div className="cursor-pointer opacity-70 hover:opacity-100 mx-2">
                <FontAwesomeIcon icon={faPlus} />
            </div>}
        />

        <div onClick={() => themeToggle()} className="cursor-pointer opacity-70 hover:opacity-100 mx-2 text-center w-[18px]">
            <FontAwesomeIcon id="theme-toggle-dark-icon" icon="fa-solid fa-moon" className="hidden" />
            <FontAwesomeIcon id="theme-toggle-light-icon" icon="fa-solid fa-sun" className="hidden" />
        </div>

    </div>
}

export default Right;