import { axios } from "../../services";
import { CHAT_LIST_COUNT } from "../actions/chatGptActions";
import * as TYPES from "../types/chatgpt";

const initialState = {
    loaded: false,
    loading: false,
    error: {},
    chats: [],
    currentChatId: null,
    messages: [],
}

let indexChatId = null;

export const chatGptReducer = (state = initialState, action) => {

    switch (action.type) {

        case TYPES.CHATGPT_MAIN_LOADING:
            return {
                ...state,
                loading: action?.payload,
                messages: action?.payload === true ? [] : state.messages,
            }

        case TYPES.CHATGPT_MAIN_LOADED:
            return { ...state, loaded: action.payload }

        case TYPES.CHATGPT_CATCH:
            return { ...state, error: axios.getCatch(action?.payload) }

        case TYPES.CHATGPT_APPEND_MESSAGE:

            let index = state.messages.findIndex(r => r?.uuid && r.uuid === action?.payload?.uuid);

            if (index >= 0) {
                state.messages.splice(index, 1, action.payload);
                return { ...state, messages: state.messages }
            } else {
                return { ...state, messages: [action.payload, ...state.messages] }
            }

        case TYPES.CHATGPT_SET_CHAT_ID:
            return { ...state, currentChatId: action.payload }

        case TYPES.OPENAI_SET_CHAT_LIST:

            indexChatId = state.chats.findIndex(r => r?.chat_id && r.chat_id === action?.payload?.chat_id);

            if (indexChatId >= 0) {
                state.chats.splice(indexChatId, 1, action.payload);
                return { ...state, chats: state.chats }
            } else {
                return { ...state, chats: [action.payload, ...state.chats].slice(0, CHAT_LIST_COUNT) }
            }

        case TYPES.OPENAI_SET_CHAT_FIRST_MESSAGE:

            indexChatId = state.chats.findIndex(
                r => r?.id && !r?.first_message && r.id === action?.payload?.chat_id
            );

            if (indexChatId >= 0) {
                state.chats.splice(indexChatId, 1, {
                    ...state.chats[indexChatId],
                    first_message: action?.payload?.message
                });
                return { ...state, chats: state.chats }
            } else {
                return { ...state }
            }

        default:
            return { ...state };
    }

}