export const APP_REDUCER_STATE = 'APP_REDUCER_STATE';

export const FETCH_CATCH = 'FETCH_CATCH';
export const FETCH_APP_START = 'FETCH_APP_START';
export const FETCH_APP_START_SUCCESS = 'FETCH_APP_START_SUCCESS';

export const FETCH_APP_USER_LOGIN = 'FETCH_APP_USER_LOGIN';
export const FETCH_APP_USER_LOGIN_FALSE = 'FETCH_APP_USER_LOGIN_FALSE';
export const FETCH_APP_USER_LOGOUT = 'FETCH_APP_USER_LOGOUT';
export const FETCH_APP_USER_LOGOUT_FALSE = 'FETCH_APP_USER_LOGOUT_FALSE';

export const SET_HEADER_RIGHT_COMPONENT = 'SET_HEADER_RIGHT_COMPONENT';

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
