import { useState } from "react";
import Content from "../../Content";
import { useEffect } from "react";
import { axios, moment } from "../../../../services";
import { Table } from "antd";

const DevicesData = () => {

    const [loading, setLoading] = useState(true);
    const [loadPage, setLoadPage] = useState(true);
    const [items, setItems] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 15,
        },
    });

    const [types, setTypes] = useState([]);
    const [devices, setDevices] = useState([]);
    const columns = [
        {
            key: 0,
            title: "Устройство",
            dataIndex: "device",
            render: (device, item) => device?.label || `#${item.device_id}`,
            filters: devices,
        },
        {
            key: 1,
            title: "Тип",
            dataIndex: "device",
            render: device => device?.type_name || "",
            filters: types,
        },
        {
            key: 2,
            title: "Значение",
            dataIndex: "value",
        },
        {
            key: 3,
            title: "Дата",
            dataIndex: "created_at",
            render: (created_at) => moment(created_at).format("DD.MM.YYYY HH:mm"),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {

        let selectFilters = {};

        for (let item in filters) {
            if (Boolean(filters[item])) {
                selectFilters[columns[item].dataIndex] = filters[item];
            }
        }

        if (JSON.stringify(selectFilters) !== JSON.stringify(tableParams?.filters || {})) {
            pagination.current = 1;
        }

        setTableParams({
            pagination,
            filters: selectFilters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setItems([]);
        }
    };

    useEffect(() => {

        setLoadPage(true);

        tableParams.page = tableParams?.pagination?.current || 1;

        axios.get(`crm/telemetry/data`, { params: tableParams })
            .then(({ data: { data, meta } }) => {
                setItems(data);
                setTableParams(p => ({
                    ...p,
                    pagination: {
                        ...p.pagination,
                        current: meta.current_page,
                        pageSize: meta.per_page,
                        total: meta.total,
                    }
                }));
                setTypes(meta.types || []);
                setDevices(meta.devices || []);
            })
            .catch(e => {

            })
            .then(() => {
                setLoading(false);
                setLoadPage(false);
            });

        // eslint-disable-next-line
    }, [JSON.stringify({
        current: tableParams?.pagination?.current || 1,
        pageSize: tableParams?.pagination?.pageSize,
        filters: tableParams?.filters || {},
    })]);

    return <Content
        title="Показания устройств"
        loading={loading}
        children={<>

            <Table
                columns={columns}
                dataSource={items.map(item => ({ ...item, key: item.id }))}
                pagination={tableParams?.pagination || {}}
                loading={loadPage}
                onChange={handleTableChange}
            />

        </>}
    />
}

export default DevicesData;