import TextArea from "antd/es/input/TextArea";

const Textarea = props => {

    const { name, placeholder, maxLength = 1000 } = props;
    const { formdata, setFormdata } = props;
    const value = formdata[name] || null;

    return <TextArea
        placeholder={placeholder}
        showCount
        maxLength={maxLength}
        rows={3}
        className="mb-3"
        value={value}
        onChange={e => setFormdata(p => ({ ...p, [name]: e?.target?.value }))}
    />
}

export default Textarea;