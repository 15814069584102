import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Input, Select, Space } from "antd";
import { useState } from "react";
const { Option } = Select;
let index = 0;

const SelectCrm = props => {

    const { name, placeholder, addItem } = props;
    const [options, setOptions] = useState(props.options || []);
    const [point, setPoint] = useState("");

    const { formdata, setFormdata } = props;
    const value = formdata[name] || null;

    const addItemHandle = (e) => {
        e.preventDefault();
        let value = point || `New item ${index++}`;
        setOptions(items => [...items, { value, lable: value }]);
        setPoint("");
    };

    return <Select
        // showSearch
        // filterOption={(input, option) => (option?.label ?? '').includes(input)}
        placeholder={placeholder}
        children={prepareOptions(options || [], name)}
        optionLabelProp="label"
        notFoundContent={<div>Данных нет</div>}
        value={value}
        onChange={value => setFormdata(p => ({ ...p, [name]: value }))}
        dropdownRender={(menu) => <>
            {menu}
            {addItem && <>
                <Divider className="my-1" />
                <Space.Compact className="w-full">
                    <Input placeholder="Добавить..." value={point} onChange={e => setPoint(e.target.value)} />
                    <Button type="primary" disabled={!Boolean(point)} onClick={addItemHandle}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Space.Compact>
            </>}
        </>}
    />
}

export const prepareOptions = (options, type = null) => {

    if (type === "status_id") {
        return options.map(o => {

            const label = <div className="flex items-center">
                <div style={{ background: o.color }} className="w-3 h-3 rounded me-2"></div>
                <span>{o.label}</span>
            </div>;

            return <Option
                key={o.value}
                value={o.value}
                label={label}
                children={label}
            />
        })
    }

    return options.map(o => <Option
        key={o.value}
        value={o.value}
        label={o.label}
        children={o.label}
    />);

}

export default SelectCrm;