import { DatePicker } from "antd"
import moment from "moment";
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

const Datetime = props => {

    const { name, placeholder } = props;

    const { formdata, setFormdata } = props;
    const value = typeof formdata[name] == "string"
        ? dayjs(formdata[name])
        : null;

    return <DatePicker
        showTime
        format="DD.MM.YYYY HH:mm"
        placeholder={placeholder}
        value={value}
        className="w-full"
        locale={locale}
        onChange={({ $d }) => setFormdata(p => ({ ...p, [name]: moment($d).format("YYYY-MM-DD HH:mm:ss") }))}
    />
}

export default Datetime;